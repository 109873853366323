<template>
  <div class="card text-center main-card-custom flex-grow-1 d-flex flex-sm-column flex-column-reverse">
  <div class="card-body main-card-body-custom">
<div>
<h3 class="form-title text-center m-md-4  my-3 pd-3 pd-md-0 d-md-block d-none">Mevcut Şirketler</h3>
  <div class="d-flex align-items-md-center justify-content-center h-100">
      <b-card-group deck class="justify-content-center">
            <b-card v-for='company in CompanyList' v-bind:key="company.id" class="mt-3" >
                <b-card-text class="text-center"><router-link :to="{ name: 'Company', params: { companyname: company.name}}"> {{company.name}}</router-link></b-card-text>
            </b-card>
     </b-card-group>
  </div>
</div>
  </div>
</div>
</template>

<script>

import { mapGetters } from "vuex";

export default {
  data: function() { return {
    CompaniesUrl: process.env.VUE_APP_API_SERVER +"/api/v1/companies",
    CompanyList: ""
    }
  },
  created(){
      this.getData()
  },
  methods:{
    getData(){
        this.authenticatedAxios.get(this.CompaniesUrl)
        .then((result)=>{
          if (result.data.length == 1){
            this.$router.push("/company/"+result.data[0].name)
          }else{
            this.CompanyList = result.data
          }
          
        })
    },
  },
  computed:{
    ...mapGetters([
      'authenticatedAxios'
    ]),
  }
}
</script>

<style>
@media (min-width: 576px){
  .card-deck {
    width: 100%;
  }
  .card-deck .card {
    flex: 1 0 25% !important;
    max-width: 25%;
  }
  .card-group .card {
    max-width: 25%;
  }

}

</style>