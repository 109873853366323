<template>
  <b-modal id="modal-3"  title="Hangi güne ait bilgileri görmek istersiniz?" ok-title = "Sorgula" cancel-title = "Geri Dön" @ok="handleBaska" @show="resetModalBaska" @hidden="resetModalBaska" >
    
    <div v-if="BaskaDayError" class="text-danger">{{BaskaDayErrorContent}}</div>
    
    <b-form-datepicker id="example-datepicker" placeholder="Lüften Tarih Seçiniz" v-model="BaskaDayValue" class="mb-2" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" locale="tr" ></b-form-datepicker>
  </b-modal>
</template>

<script>

export default {
  name: 'QueryOtherDay',
  data() {return{
    BaskaDayValue:"",
    BaskaDayError: false,
    BaskaDayErrorContent: "",
  }},
  methods:{
    resetModalBaska() {
      this.BaskaDayValue = ""
      this.BaskaDayError = false},
    handleBaska(bvModalEvt){
      bvModalEvt.preventDefault()
      if (new Date(this.BaskaDayValue) < new Date()){

        const body2send = {start_date: this.BaskaDayValue + " 00:00:00", end_date: this.BaskaDayValue + " 23:59:59"}
        this.$emit('buildSSEconnectioninChild', 'otherDayShifts' ,body2send)

        this.$nextTick(() => {
        this.$bvModal.hide('modal-3')
        })

      }else if(new Date(this.BaskaDayValue) > new Date() ){
        this.BaskaDayError = true
        this.BaskaDayErrorContent = "Seçtiğiniz tarih bugünden büyük olamaz."
        return
      }
      else{
        this.BaskaDayError = true
        this.BaskaDayErrorContent = "Lütfen Sorgulamak istediğiniz bir tarih seçiniz."
        return
      } 
    }
  }
}
</script>

<style>

</style>