<template>
  <div class="d-flex flex-column  h-100">
    <div class="d-flex justify-content-end">
      <router-link to="register" tag="a" class="btn btn-back-custom mt-3 d-none d-md-inline-block" > Kayıt Ol</router-link>
    </div>
    <div class="d-flex justify-content-center d-block d-md-none  overlay-class">
      <router-link to="login" tag="a" class="btn btn-active-custom btn-left-custom top-50" > Giriş Yap</router-link>
      <router-link to="register" tag="a" class="btn btn-passive-custom btn-right-custom bottom-0" > Kayıt Ol</router-link>
    </div>
    <div class="d-flex align-items-md-center justify-content-center h-100 custom-initial m-auto" >
      <div class="w-100">
        <h3 class="form-title text-center m-md-4 my-3 pd-3 pd-md-0 d-none d-md-block">Kullanıcı Girişi</h3>
        <div v-if="showThankYou" class="bg-success text-white text-center p-2 my-2">
            Mail adresinize şifre yenileme linki yollandı. 
        </div>
        <div v-if="showFailedPasswordRenewal" class="bg-danger text-white text-center p-2 my-2">
            Şifreniz yenilenemedi. Lütfen tekrar yenileme talebinde bulunun. 
        </div>
        <div v-if="showSuccessPasswordRenewal" class="bg-success text-white text-center p-2 my-2">
            Şifreniz başarıyla yenilendi. Lütfen yeni şifrenizle giriş yapınız. 
        </div>
        <div v-if="showRegistered" class="bg-success text-white text-center p-2 my-2">
            Hesabınız oluşturuldu. <br /> Hesabınızı aktive etmek için emailinizi kontrol ediniz. 
        </div>
        <div v-if="showActivated" class="bg-success text-white text-center p-2 my-2">
            Hesabınız aktive oldu. <br /> Kullanıcı adınız ve şifrenizle giriş yapabilirsiniz
        </div>
        <div v-if="showFailedActivated" class="bg-danger text-white text-center p-2 my-2">
            Hesabınız aktive olmadı. <br /> Lütfen mailinizi kontrol ediniz.
        </div>
        <form>
          <validation-error v-bind:validation="$v.username" />
          <div class="input-group mb-4">
            <div  class="input-group-prepend igp-custom"><i class="input-group-text igt-custom  fa fa-user"></i> </div>
            <input v-model="$v.username.$model"  type="username" class="form-control fci-custom" id="email" placeholder="Lütfen kullanıcı adınızı giriniz." />
          </div>
          <validation-error v-bind:validation="$v.password" />
          <div class="input-group igp-custom mb-4">
            <div  class="input-group-prepend igp-custom"><i class="input-group-text igt-custom fa fa-key-modern"></i> </div>
            <input v-model="$v.password.$model" class="form-control fci-custom" type="password" id="password" placeholder="Lütfen şifrenizi giriniz." />
          </div>
          <div class="form-group form-check d-flex justify-content-between fgl-custom" >
            <label for="remember" class="form-check-label"  >
              <input v-model="rmCheck" class="form-check-input" type="checkbox" id="remember">Beni hatırla
            </label>
            <span v-b-modal.forgotPassword>Şifremi unuttum?</span>
          </div>
          <div v-if="showFailureMessage" class="bg-danger text-white text-center p-2 my-2">
            Giriş bilgileriniz yanlış. Lütfen kontrol ediniz.
          </div>
          <div class="d-flex justify-content-center">
            <button  v-on:click="handleAuth" type="button" class="btn btn-custom px-4 mx-3 "> Giriş Yap</button>
          </div>
        </form>
      </div>
    </div>
    <b-modal id="forgotPassword" centered hide-footer title="Şifremi Unuttum" class="text-center" header-class="text-center">

      <div class="d-flex align-items-center justify-content-center">
      <form >
        <validation-error v-bind:validation="$v.email" />
        <div class="input-group mb-4">
          <div  class="input-group-prepend igp-custom"><i class="input-group-text igt-custom  fa fa-envelope"></i> </div>
          <input v-model="$v.email.$model"  type="email"  class="form-control fci-custom" id="email_forgotten"  placeholder="Lütfen email adresinizi giriniz." />
        </div>

        <div class="d-flex justify-content-center">
          <button    v-on:click="handlePasswordResetRequest" type="button" class="btn btn-custom px-4 mx-3 "> Şifreyi Sıfırla</button>
        </div>
      </form>
      </div>
  </b-modal>
  </div>


</template>

<script>
  import { required,email,minLength } from "vuelidate/lib/validators";
  import { mapActions } from "vuex";
  import ValidationError  from "./ValidationError";
  
  export default {
    components: { ValidationError },
    data: function() { return {
      username: "", 
      password: "",
      email: "",
      rmCheck: false,
      showFailureMessage: false,
      showThankYou:false,
      showFailedPasswordRenewal: false,
      showSuccessPasswordRenewal: false,
      showRegistered: false,
      showActivated: false,
      showFailedActivated: false,
      }
    }, 
    validations: {
      username: { required, email },
      password: { required, minLength: minLength(8) },
      email: {required,email}
    },
    methods: {
      ...mapActions(["authenticate","passwordRenewalRequest"]),
      async handleAuth() {
        this.onChange();
        this.$v.$touch();
        if (!this.$v.username.$invalid&&!this.$v.password.$invalid) {
          this.authenticate({ email: this.username, password: this.password })
          .then((result)=>{
            if (result == -1){
              this.showFailureMessage = true;
            }else{
              if (localStorage.rememberMe == "true"){
              localStorage.setItem('username',this.username)
            }
            this.$router.push("/companies");
          }})
        }        
      },
      handlePasswordResetRequest(){
        this.onChange();
        this.$v.email.$touch();
        if (!this.$v.email.$invalid) {
          this.passwordRenewalRequest(this.email)
          this.email =""
          this.$bvModal.hide('forgotPassword')
          this.showThankYou = true;
          this.showFailedPasswordRenewal = false;
          this.showSuccessPasswordRenewal = false;
        }
      },
      onChange(){
          this.showFailureMessage = false;
          this.showThankYou = false;
          this.showFailedPasswordRenewal = false;
          this.showSuccessPasswordRenewal = false;
          this.showRegistered = false;
          this.showActivated = false;
          this.showFailedActivated = false;
      }   
    },
    watch: {
      rmCheck: function(val) {
        if (val == true){
          localStorage.setItem('rememberMe', val)
        }else if(val == false){
          localStorage.setItem('rememberMe', val)
        }
      }
    },
    mounted() {
    if (localStorage.rememberMe == "true") {
      this.rmCheck = true;
      if (localStorage.username) {
      this.username = localStorage.username;
      }
    }else{
      this.username = ""
      this.password = ""
    }
    if(this.$route.query.result){
      if (this.$route.query.result == "failed"){
        this.showFailedPasswordRenewal = true
      }else if(this.$route.query.result == "successful"){
        this.showSuccessPasswordRenewal = true
      }else if(this.$route.query.result == "registered"){
        this.showRegistered = true
      }else if(this.$route.query.result == "activated"){
        this.showActivated = true
      }else if(this.$route.query.result == "activation_failed"){
        this.showFailedActivated = true
      }
    }
  }
}
</script>

<style>
a.btn-active-custom {
  font-family: acumin-pro, sans-serif;
  color: #f26600;
  font-weight: 700;
  font-style: normal;
  font-size: 1.5rem;
  background-color: #f0f0f0;
  position: relative;
  top: -1em;
  border: none;
}
a.btn-passive-custom {
  font-family: acumin-pro, sans-serif;
  color: white;
  background: linear-gradient(
    to right,
    rgba(242, 102, 0, 1),
    rgba(242, 223, 0, 1)
  );
  font-weight: 700;
  font-style: normal;
  font-size: 1.5rem;
  background-size: calc(100% + 4px);
  position: relative;
  top: -1em;
}
a.btn-back-custom {
  font-family: acumin-pro, sans-serif;
  color: #f26600;
  font-weight: 700;
  font-style: normal;
  font-size: 1rem;
  box-shadow: 2px 2px 5px grey;
}
button.btn-custom {
  background: linear-gradient(
    to right,
    rgba(242, 102, 0, 1),
    rgba(242, 223, 0, 1),
  );
  font-family: acumin-pro, sans-serif;
  color: white;
  font-weight: 700;
  font-style: normal;
  font-size: 1.2rem;
  min-width: 10rem;
  background-size: calc(100% + 4px);
  outline: none;
}

h3.form-title {
  font-family: acumin-pro, sans-serif;
  color: #797979;
  font-weight: 700;
  font-style: normal;
  font-size: 2rem;
}
input.fci-custom {
  background-color: #f0f0f0;
  border-left: 0 solid transparent;
}
i.igt-custom {
  color: #f26600;
  background-color: #f0f0f0;
}

div.igp-custom {
  border-right: 0;
}
div.fgl-custom {
  font-family: acumin-pro, sans-serif;
  color: #797979;
  font-weight: 700;
  font-style: normal;
  font-size: 1rem;
}

@media (max-width: 568px) {
h3.form-title {
  font-weight: 700;
  font-size: 1.5rem;
}
h3{
  padding-bottom: 1em;
  padding-top: 0.5em;
}
div.custom-initial{
  width: 75%;
}
}
@media (min-width: 568px) {
  h3.form-title {
  font-weight: 700;
  font-size: 2rem;
}
div.custom-initial{
  width: 50%;
}
}

</style>