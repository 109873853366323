<template>
   <GChart
    type="ColumnChart"
    :data="chartData"
    :options="chartOptions"
  />
</template>

<script>
import { GChart } from 'vue-google-charts/legacy'

export default {
  props: ['chartD','title', 'pColor', 'vAxisTitle'],
components: {
    GChart
  },
data () {
    return {
      chartData: this.chartD,
      chartOptions: {
        bar: {groupWidth: "100%"},
        isStacked: true,
        height:350,
        colors: this.pColor,
        chart: {
          title: this.title,
        },
        legend: { position: 'top'},
        chartArea: {width: '78%'},
        vAxis: {
          title: this.vAxisTitle
        },
        hAxis: {
          title: 'Vardiya Zamanı',
          format: 'HH:mm',
        }
      }
    }
  }
}
</script>

<style>

</style>