<template>
  <svg class="svgGraph" width="21em" height="21em" viewbox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" version="1.1" >
    <g class="graphRotate">
      <circle cx="50%" cy="50%" r="7em" stroke="#707070" stroke-width="1.1rem" fill="None" stroke-opacity="0.6" stroke-dasharray="33em, 44em" />
      <circle cx="50%" cy="50%" r="7em" v-bind:stroke="productColors[(index%4)]" stroke-width="1.1em" fill="#FDB900" fill-opacity="0.0"       v-bind:stroke-dasharray="CalculateGraph(item.production_efficiency/100).dasharray"   />
    </g>
    
    <circle v-bind:cx="CalculateGraph(item.production_efficiency/100).small_cx" v-bind:cy="CalculateGraph(item.production_efficiency/100).small_cy" r="1em" fill="#fff" v-bind:stroke="productColors[(index%4)]" stroke-width="0.4em"/>

    <text text-anchor="middle" x="50%" y="27%" class="TextInGraph" fill="#707070" >Üretilen</text>
    <text  text-anchor="middle" x="48%" y="37%" class="TextInGraphBig2" fill="#707070" >{{item.count}}</text>
    <text  text-anchor="middle" x="64%" y="32%" class="TextInGraphMini" fill="#707070" >Adet</text>
    <text text-anchor="middle" x="50%" y="45%" class="TextInGraph" v-bind:fill="productColors[(index%4)]" >Üretim Verimliliği</text>
    <text text-anchor="middle" x="50%" y="55%" class="TextInGraphBig" v-bind:fill="productColors[(index%4)]" >%{{Math.floor(item.production_efficiency)}}</text>
    <text text-anchor="middle" x="50%" y="63%" class="TextInGraph" fill="#707070" >Kapasite</text>
    <text  text-anchor="middle" x="64%" y="67%" class="TextInGraphMini" fill="#707070" >Adet/Saat</text>
    <text id="kapasiteCount" text-anchor="middle" x="44%" y="73%" class="TextInGraphBig2" fill="#707070" >{{Math.floor(item.capacity)}}</text>
  </svg>
</template>

<script>
export default {
  name: 'ProductSVG',
   props: ['productColors','item','index'],
  data() {return{
    
  }},
  methods:{ 
    CalculateGraph(percentage){
      var da = percentage * 33
      var cx = 10.5+Math.cos((225/180)*Math.PI-(270/180)*Math.PI*percentage)*7
      var cy = 10.5-Math.sin((225/180)*Math.PI-(270/180)*Math.PI*percentage)*7
      return {small_cx: cx+"em",small_cy: cy+"em",dasharray: da + "em, 44em"}
    },
  },
  computed:{

  }
}
</script>

<style>

</style>