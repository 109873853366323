<template>
<div class="card text-center main-card-custom flex-grow-1 d-flex flex-sm-column flex-column-reverse">
  <div class="card-header main-card-header-custom d-flex">
    <ul class="nav nav-tabs card-header-tabs">
      <li  class="nav-item">
        <router-link :to="{name:'Profile'}"  class="nav-link active">Profil</router-link>
      </li> 
      <li  class="nav-item">
        <router-link :to="{name:'ChangePassword'}"  class="nav-link passive">Şifre Değiştir</router-link>
      </li>
    </ul>
  </div>
  <div class="card-body main-card-body-custom">
<div>
<h3 class="form-title text-center m-md-4  my-3 pd-3 pd-md-0 d-md-block">Profil Güncelleme</h3>
  <div class="d-flex align-items-md-center justify-content-center h-100">
            
        <form>
          <validation-error v-bind:validation="$v.FirstName" />
          <div class="input-group mb-4">
            <div  class="input-group-prepend igp-custom"><i class="input-group-text igt-custom  fa fa-user"></i> </div>
            <input v-model="$v.FirstName.$model"  class="form-control fci-custom" id="FirstName" placeholder="Adınız" />
          </div>
          <validation-error v-bind:validation="$v.LastName" />
          <div class="input-group mb-4">
            <div  class="input-group-prepend igp-custom"><i class="input-group-text igt-custom  fa fa-user"></i> </div>
            <input v-model="$v.LastName.$model"   class="form-control fci-custom" id="LastName" placeholder="Soyadınız" />
          </div>
          <div v-if="showPhoneError" class="text-danger">
            <div >{{ message }}</div>
          </div>
          <div class="input-group igp-custom mb-4">
            <div  class="input-group-prepend igp-custom"><i class="input-group-text igt-custom fa fa-tty"></i> </div>
            <input v-model="PhoneNumber" class="form-control fci-custom"   @keypress="onChange($event)" id="PhoneNumber" placeholder="(5xx)xxx xx xx" />
          </div>
          <div v-if="showFailureMessage" class="bg-danger text-white text-center p-2 my-2">
            Profil bilgileriniz değiştirilemedi.
          </div>
          <div v-if="showSuccess" class="bg-success text-white text-center p-2 my-2">
            Profil bilgileriniz başarıyla güncellendi.
          </div>
          <div class="d-flex justify-content-center">
            <button  v-on:click="handleProfileUpdate"   type="button" class="btn btn-custom px-4 mx-3 ">Profil Bilgilerini Güncelle</button>
          </div>
        </form>
  </div>
</div>
  </div>
</div>
</template>

<script>
  import { required,minLength  } from "vuelidate/lib/validators";
  import ValidationError  from "./ValidationError";
  import parsePhoneNumber from 'libphonenumber-js'
  import { AsYouType } from 'libphonenumber-js'
  import { mapGetters } from "vuex";

  export default {
    components: { ValidationError },
        data: function() { return {
      FirstName: "",
      LastName: "",
      PhoneNumber: "",
      message:"",
      email:"",
      showFailureMessage: false,
      showSuccess:false,
      showPhoneError: false,
      UserUrl: process.env.VUE_APP_API_SERVER +"/api/v1/user",
      }
    },
    mounted(){
      this.getData()
    },
    validations: {
      FirstName: { required,minLength: minLength(3) },
      LastName: { required, minLength: minLength(3) },
    },
    methods:{
      getData(){
          this.authenticatedAxios.get(this.UserUrl)
          .then((result)=>{
            this.email = result.data.email
            this.FirstName = result.data.profile.first_name
            this.LastName = result.data.profile.last_name
            this.PhoneNumber = new AsYouType('US').input(result.data.profile.phone)
          })
      },
      handleProfileUpdate(){
        this.$v.$touch();
        if (!this.$v.$invalid && this.checkBeforeSubmit()) {
          this.authenticatedAxios.put(this.UserUrl,{email:this.email,profile:{first_name:this.FirstName,last_name:this.LastName,phone: parsePhoneNumber(this.PhoneNumber,'US').formatNational()}})
          .then(()=>{this.showSuccess=true})
          .catch(()=>{this.showFailureMessage=true})
          
        }
      },
      checkBeforeSubmit(){
        this.showSuccess=false
        this.showFailureMessage=false
        if(this.PhoneNumber.length==14){
          return true
        }else{
          this.message = "Lütfen geçerli bir cep telefonu numarası giriniz."
          this.showPhoneError = true
          return false
        }
      },
      onChange($event){
        let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
        if(this.PhoneNumber.length==0){
          if(keyCode != 53){
          this.message = "5 ile başlayan cep telefonu numarası giriniz."
          this.showPhoneError = true
            $event.preventDefault();
          }else{
            this.showPhoneError = false
          }
        }else{
          if(this.PhoneNumber.length<14){
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
              this.message = "Sadece Numara giriniz."
              this.showPhoneError = true
              $event.preventDefault();
            }else{
              this.showPhoneError = false
            }
          }else if(this.PhoneNumber.length==14){
            if(keyCode != 8){
              $event.preventDefault();
            }
          }
        }
        this.PhoneNumber = this.InteralPhoneNumber
      }
    },
    computed:{
      InteralPhoneNumber(){
        return new AsYouType('US').input(this.PhoneNumber)
      },
      ...mapGetters([
      'authenticatedAxios'
     ]),
    }
    
    }
</script>

<style>
button.btn-custom {
  background: linear-gradient(
    to right,
    rgba(242, 102, 0, 1),
    rgba(242, 223, 0, 1)
  );
  font-family: acumin-pro, sans-serif;
  color: white;
  font-weight: 700;
  font-style: normal;
  font-size: 1.2rem;
  min-width: 10rem;
  background-size: calc(100% + 4px);
}

h3.form-title {
  font-family: acumin-pro, sans-serif;
  color: #797979;
  font-weight: 700;
  font-style: normal;
  font-size: 2rem;
}
input.fci-custom {
  background-color: #fcfcfc;
  border-left: 0 solid transparent;
}
i.igt-custom {
  color: #f26600;
  background-color: #fcfcfc;
}
i.igt-custom2 {
  color: #707070;
  background-color: #fcfcfc;
}
div.igp-custom {
  border-right: 0;
}
div.fgl-custom {
  font-family: acumin-pro, sans-serif;
  color: #797979;
  font-weight: 700;
  font-style: normal;
  font-size: 1rem;
}

@media (max-width: 568px) {
h3.form-title {
  font-weight: 700;
  font-size: 1.5rem;
}
h3{
  padding-bottom: 1em;
  padding-top: 0.5em;
}
}
@media (min-width: 568px) {
  h3.form-title {
  font-weight: 700;
  font-size: 2rem;
}
}

a.nav-link.active {
    font-family: acumin-pro, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 1rem;
  }
  a.nav-link {
  font-family: acumin-pro, sans-serif;
  color: white;
  font-weight: 700;
  font-style: normal;
  font-size: 1rem;
}
  a.nav-link.passive {
  font-family: acumin-pro, sans-serif;
  color: white;
  font-weight: 700;
  font-style: normal;
  font-size: 0.93rem;
}
</style>