<template>
  <ul id="DeviceNavBar" class="nav nav-tabs flex-lg-column  h-100 p-0">
    <li 
      v-for="(tab,index) in tabs"
      :key="tab"
      :class="['nav-item', 'DBnav-item', 'flex-grow-1', 'd-flex', 'flex-column', 'justify-content-center',(currentShiftId === index && baska==false ? 'active':'')]"
       v-on:click="$emit('changePageinChild' , index)"
    >
      <i 
        :class="['fa',(tabsLogo[index]),'fa-3x',(currentShiftId === index && baska==false? 'active' : 'passive')]" 
        aria-hidden="true">
      </i>
      {{tab}}
    </li>
    <li class="nav-item DBnav-item flex-grow-1 d-flex flex-column justify-content-center" v-bind:class="{'active': baska == true }"  v-b-modal.modal-3 >
      <i class="fa fa-question fa-3x " v-bind:class="baska == true ? 'active':'passive'" aria-hidden="true"></i>
        Başka
    </li>
  </ul>
</template>

<script>
export default {
  name: 'DeviceNavBar',
  props: ['currentShiftId','baska'],
  data (){return{
      tabs: ['Güncel', 'Bir Önceki', 'İki Önceki'],
      tabsLogo: ['fa-clock-o','fa-angle-left','fa-angle-double-left']
  }}
}
</script>

<style>
#DeviceNavBar.nav.nav-tabs{
  border-bottom:none !important
}

li.DBnav-item{
  cursor: pointer;
    overflow: hidden;
    outline: none;
    color: #F0f0f0;
    font-size: 0.9rem;
    border-top:  1px solid rgba(240, 240, 240,0.5);
}
li.DBnav-item.active{
  background-color: rgba(240, 240, 240, 0.8 );
  border: none !important; 
  color: #707070;
}
li.DBnav-item{
  background-color
  : rgba(0, 140, 242, 1);
}
</style>