import Vue from 'vue';
import Vuex from 'vuex';
import router from '../router';

//import {_} from 'vue-underscore';

import AuthModule from "./auth";

Vue.use(Vuex)

const baseUrl = process.env.VUE_APP_API_SERVER;
const businessUrl = `${baseUrl}/api/v1/businesses`;

export default new Vuex.Store({
  modules:{auth: AuthModule},
  strict: true,
  state: {
    companies:"",
    branches:"", 
    departments:"", 
    user_devices:"",
    errors: [],
    mqttData:[],
  },
  getters:{
    findCompanyByName: (state) => companyName => {
      for (const key of Object.keys(state.companies)){
        if (state.companies[key].name == companyName){
          return  state.companies[key]
        }
      }
      return -1
    },

    findBranchByName: (state) => (companyName, branchName) =>{
      for (const key of Object.keys(state.companies)){
        if (state.companies[key].name == companyName){
          for (const key of Object.keys(state.branches)){
            if (state.branches[key].name== branchName){
              return state.branches[key]
            }
          }
        }
      }
      return -1
    },
    findDeviceByName:(state) => (branchName,departmentName,deviceName)  => {
      for (const key of Object.keys(state.branches)){
        if (state.branches[key].name == branchName){
          for ( const element of state.branches[key].departments) {
            if (state.departments[element].name == departmentName){
              for ( const element2 of state.departments[element].user_devices){
                if (state.user_devices[element2].name == deviceName ){
                  return state.user_devices[element2]
                }
              }
            }
          }
        }
      }
      return -1
    },
    currentCompany:(state,getters)=>{
      if (typeof router.currentRoute.params.companyname === 'undefined') {
        return state.companies[Object.keys(state.companies)[0]]
      }else{
        return  getters.findCompanyByName (router.currentRoute.params.companyname)
      }
    },
    currentBraches:(state,getters)=>{
      if (getters.currentCompany === undefined ){return}
      if (getters.currentCompany === -1 ){return}
      var array = [];
      for (const key of getters.currentCompany.branches){
        array.push(state.branches[key])
      }
      return array
    },
    currentBranch: (state,getters) => {
      if (getters.currentCompany === undefined){return}
      if (getters.currentCompany === -1 ){return}
      let x =  getters.currentCompany
      if (typeof router.currentRoute.params.branchname === 'undefined') {
        return state.branches[x.branches[0]]
      }else{
        return getters.findBranchByName(getters.currentCompany.name,router.currentRoute.params.branchname)
      }
    },
    currentDepartments:(state,getters) => {
      var array = [];
      if (getters.currentBranch === undefined){return}
      if (getters.currentBranch === -1 ){return}
      let x =  getters.currentBranch
      for (const element of x.departments){
        var array2 = [];
        for (const element2 of state.departments[element]["user_devices"]){
          array2.push({id:state.user_devices[element2].id,name:state.user_devices[element2].name,status:state.user_devices[element2].status, type:state.user_devices[element2].type, date_updated:state.user_devices[element2].date_updated} )
        }
        array.push( {id:state.departments[element].id, name:state.departments[element].name,user_devices:array2})
      }
      return array
    },
  },

  mutations: {
    setBusiness(state,data){
      var entity = data.bdata.reduce((result,item)=>{
          result.companies[item.id] = {"id":item.id, "name":item.name, "type":item.type, "branches":item.branches.map( b => b.id)}
          item.branches.reduce((result2,item2)=>{
            result.branches[item2.id] = {"id":item2.id, "name":item2.name, "departments":item2.departments.map( d => d.id)}
            item2.departments.reduce((result3,item3)=>{
                result.departments[item3.id] = {"id":item3.id, "name":item3.name, "user_devices":item3["user_devices"].map(x=>x.id)}
                item3.user_devices.reduce((result4,item4)=>{
                  result.user_devices[item4.id] = {"id":item4.id, "name":item4.name, "type":item4.type, "status":item4.status, "date_updated":item4.date_updated }
                },{})
            },{})
          },{})
          return result;
        },{companies:{},branches:{}, departments:{}, user_devices:{}})
      
        console.log("set branches")
      state.companies = entity.companies
      state.branches = entity.branches
      state.departments = entity.departments
      state.user_devices = entity.user_devices
    },
    updateStatus(state,data){
      let recieved =  data
      state.user_devices[recieved["ud_id"]].status = recieved["ud_status"]
    },
    LoadJwt(state,data){
      state.auth.access = data
    }
  },
  actions: {
    async getBusiness(context){
      const result = await context.getters.authenticatedAxios.get(businessUrl)
      if (result.status == 200) {
        let bdata = result.data
        context.commit("setBusiness",{bdata})
        return 1
      }else{
        return -1
      }
    },
  }
})
