<template>
<div class="card text-center main-card-custom flex-grow-1 d-flex flex-sm-column flex-column-reverse">
  <div class="card-header main-card-header-custom d-flex ">
    <ul class="nav nav-tabs card-header-tabs">
      <li  class="nav-item">
        <a  class="nav-link active"  >Sık Sorulan Sorular</a>
      </li>
    </ul>
  </div>
  <div class="card-body main-card-body-custom">
    <div v-html="markdownToHtml"></div>
  </div>
</div>
</template>


<script>
import {marked} from 'marked';
export default {
  name:'Help',
   data(){
   return {
     markdown:  '# Sık Sorulan Sorular\n'+
'\n'+
'###  Ardeo Nedir?\n'+
'\n'+
'Ardeo; Üretim verilerinizin uzaktan takibini sağlayabileceğiniz, anlık olarak makinenizin çalışma durumunu, verimliliğini ve benzer kayıtları görebileceğiniz bir üründür. \n'+
'\n'+
'### ArdeoHub Nedir?\n'+
'ArdeoHub; Ardeo ürün ailesinin verilerini görebileceğiniz web sitesi formatındaki platformdur. \n'+
'\n'+
'### ArdeoHub\'a Nasıl Kayıt olurum?\n'+
'Bunun için bir ürün satış bayisi ile temas kurup, istenilen ürünün tedarik edilmesi gerekmektedir. Kurulumu yapcak firma ürünü sahanızda devreye aldıktan sonra, firmanızın mail uzantısıyla ArdeoHub platformuna kayıt olup, anında üretim verilerinizi gözlemleyebilirsiniz. \n'+
'\n'+
'### ArdeoHub\'u Nasıl Kullanırım?\n'+
'Kayıt işlemlerinizi tamamladıktan sonra, platform üzerinden makinenizin durumunu, vardiya bazında neler ürettiğini, duruşlarını, verimlilik bilgilerini ve benzeri bilgileri anlık görüntüleyebilir ve anlık olarak takip edebilirsiniz. \n'+
'\n'+
'### Kişisel ve şirket bilgilerim güvende mi?\n'+
'Güvenliğiniz bizim için önemli. Bu sebeple bizimle paylaşacağınız kişisel verileriniz hassasiyetle korunmaktadır.\n' +
'Biz, Ardeo olarak, bu gizlilik ve kişisel verilerin korunması politikası ile, hangi kişisel verilerinizin hangi amaçla işleneceği, işlenen verilerin kimlerle ve neden paylaşılabileceği, veri işleme yöntemimiz ve hukuki sebeplerimiz ile; işlenen verilerinize ilişkin haklarınızın neler olduğu hususunda sizleri aydınlatmayı amaçlıyoruz.\n' +
'Bizimle paylaştığınız kişisel verileriniz sadece analiz yapmak suretiyle; sunduğumuz hizmetlerin gerekliliklerini en iyi şekilde yerine getirebilmek, bu hizmetlere sizin tarafınızdan ulaşılabilmesini ve maksimum düzeyde faydalanılabilmesini sağlamak, hizmetlerimizi, ihtiyaçlarınız doğrultusunda geliştirebilmek ve sizleri daha geniş kapsamlı hizmet sağlayıcıları ile yasal çerçeveler içerisinde buluşturabilmek ve kanundan doğan zorunlulukların (kişisel verilerin talep halinde adli ve idari makamlarla paylaşılması) yerine getirilebilmesi amacıyla, sözleşme ve hizmet süresince, amacına uygun ve ölçülü bir şekilde işlenecek ve güncellenecektir.\n' +
'Bizimle paylaştığınız kişisel verileriniz; hiçbir kurum yada kuruluşlarla yada herhangi bir 3. Şahıs ile, kesinlikle paylaşılmamaktadır. Verileriniz gizli tutulmaktadır. \n' +
'\n'+
'### Uygulama ile ilgili bir problemim olduğunda ne yapmalıyım?\n'+
'info@ardeohub.com mail adresine 7/24 mail atabilirsiniz. Sizinle Teknik destek birimimiz vasıtasıyla en kısa sürede temasa geçeceğiz. \n'
   };
 },
 computed: {
   markdownToHtml(){
     return marked(this.markdown);
   }

 }

}

</script>

<style>
#help {

  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;

}
h3{
  text-align: left;
}
p{
  text-align: left;
  padding:0 1em 0 1em ;
}

  a.nav-link.active {
    font-family: acumin-pro, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 1rem;
  }
  a.nav-link {
  font-family: acumin-pro, sans-serif;
  color: white;
  font-weight: 700;
  font-style: normal;
  font-size: 1rem;
}
  a.nav-link.passive {
  font-family: acumin-pro, sans-serif;
  color: white;
  font-weight: 700;
  font-style: normal;
  font-size: 0.93rem;
}


</style>