<template>
  <div class="card text-center main-card-custom flex-grow-1 d-flex flex-sm-column flex-column-reverse">
    <div class="card-header main-card-header-custom d-flex">
      <ul class="nav nav-tabs card-header-tabs">
        <li  class="nav-item">
          <router-link :to="{name:'Profile'}"  class="nav-link passive">Profil</router-link>
        </li> 
        <li  class="nav-item">
          <router-link :to="{name:'ChangePassword'}"  class="nav-link active">Şifre Değiştir</router-link>
        </li>
      </ul>
    </div>
    <div class="card-body main-card-body-custom">
      <div>
        <h3 class="form-title text-center m-md-4  my-3 pd-3 pd-md-0 d-md-block">Şifre Değiştirme</h3>
        <div class="d-flex align-items-md-center justify-content-center h-100">   
          <form>
            <validation-error v-bind:validation="$v.passwordOld" />
            <div class="input-group mb-4">
              <div  class="input-group-prepend igp-custom"><i class="input-group-text igt-custom2  fa fa-key-modern"></i> </div>
              <input v-model="$v.passwordOld.$model" @change="onChange($event)" class="form-control fci-custom" type="password" id="passwordOld" placeholder="Eski şifrenizi giriniz." />
            </div>
            <validation-error v-bind:validation="$v.password" />
            <div class="input-group mb-4">
              <div  class="input-group-prepend igp-custom"><i class="input-group-text igt-custom  fa fa-key-modern"></i> </div>
              <input v-model="$v.password.$model" @change="onChange($event)"  class="form-control fci-custom" type="password" id="password" placeholder="Yeni şifrenizi giriniz." />
            </div>
            <validation-error v-bind:validation="$v.repassword" />
            <div class="input-group igp-custom mb-4">
              <div  class="input-group-prepend igp-custom"><i class="input-group-text igt-custom fa fa-key-modern"></i> </div>
              <input v-model="$v.repassword.$model" @change="onChange($event)" class="form-control fci-custom" type="password" id="repassword" placeholder="Yeni şifrenizi tekrar giriniz." />
            </div>
            <div v-if="showFailureMessage" class="bg-danger text-white text-center p-2 my-2">
              {{ErrorMessage}}
            </div>
            <div v-if="showSuccess" class="bg-success text-white text-center p-2 my-2">
              Şifreniz başarıyla değiştirilmiştir.
            </div>
            <div class="d-flex justify-content-center">
              <button  v-on:click="handlePasswordReset" type="button" class="btn btn-custom px-4 mx-3 "> Şifreyi Değiştir</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>  
</template>

<script>
  import { required,minLength,sameAs, numeric, not  } from "vuelidate/lib/validators";
  import { mapActions,mapGetters } from "vuex";
  import ValidationError  from "./ValidationError";
 
  export default {
    components: { ValidationError },
    data: function() { return {
      password: "",
      passwordOld: "",
      repassword: "",
      showSuccess:false,
      showFailureMessage: false,
      ErrorMessage: "",
      changePasswordUrl: process.env.VUE_APP_API_SERVER +"/api/v1/user/password",
      }
    }, 
    computed: {
    ...mapGetters([
      'authenticatedAxios'
     ]),
    },
    validations: {
      passwordOld: { required },
      password: { required, minLength: minLength(8), notNumeric: not(numeric) },
      repassword: { required, sameAsPassword: sameAs('password') },
    },
    methods: {
      ...mapActions(["passwordRenewal"]),
      handlePasswordReset(){
        this.$v.$touch();
        if (!this.$v.$invalid ) {
          this.authenticatedAxios.put(this.changePasswordUrl,{old_password: this.passwordOld,password: this.repassword})
          .then(()=>{this.showSuccess = true})
          .catch((error)=>{
            if (error.response.status != 500){
              let data = error.response.data
              for(var propName in data) {
                if(Object.prototype.hasOwnProperty.call(data, propName)) {
                    var propValue = data[propName];
                }
              }
              this.showFailureMessage= true,
              this.ErrorMessage= propValue[0]
            }else{
              this.showFailureMessage= true,
              this.ErrorMessage= "Şifreniz değiştirilemedi."
            }
          })
            
        }
      },
      onChange(){
          this.showFailureMessage = false;
          this.showSuccess = false;
      },

    }
  }
  
</script>

<style>
button.btn-custom {
  background: linear-gradient(
    to right,
    rgba(242, 102, 0, 1),
    rgba(242, 223, 0, 1)
  );
  font-family: acumin-pro, sans-serif;
  color: white;
  font-weight: 700;
  font-style: normal;
  font-size: 1.2rem;
  min-width: 10rem;
  background-size: calc(100% + 4px);
}

h3.form-title {
  font-family: acumin-pro, sans-serif;
  color: #797979;
  font-weight: 700;
  font-style: normal;
  font-size: 2rem;
}
input.fci-custom {
  background-color: #fcfcfc;
  border-left: 0 solid transparent;
}
i.igt-custom {
  color: #f26600;
  background-color: #fcfcfc;
}
i.igt-custom2 {
  color: #707070;
  background-color: #fcfcfc;
}
div.igp-custom {
  border-right: 0;
}
div.fgl-custom {
  font-family: acumin-pro, sans-serif;
  color: #797979;
  font-weight: 700;
  font-style: normal;
  font-size: 1rem;
}

@media (max-width: 568px) {
h3.form-title {
  font-weight: 700;
  font-size: 1.5rem;
}
h3{
  padding-bottom: 1em;
  padding-top: 0.5em;
}
}
@media (min-width: 568px) {
  h3.form-title {
  font-weight: 700;
  font-size: 2rem;
}
}

a.nav-link.active {
    font-family: acumin-pro, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 1rem;
  }
  a.nav-link {
  font-family: acumin-pro, sans-serif;
  color: white;
  font-weight: 700;
  font-style: normal;
  font-size: 1rem;
}
  a.nav-link.passive {
  font-family: acumin-pro, sans-serif;
  color: white;
  font-weight: 700;
  font-style: normal;
  font-size: 0.93rem;
}
</style>