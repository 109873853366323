<template>
  <div class="d-flex flex-column  h-100">
    <div class="d-flex justify-content-end">
      <router-link tag="a" class="btn btn-back-custom mt-3 d-none d-md-inline-block" to="login"> Giriş Yap</router-link>
    </div>
    <div class="d-flex justify-content-center d-block d-md-none  overlay-class">
      <router-link to="login" tag="a" class="btn btn-passive-custom btn-left-custom top-50" > Giriş Yap</router-link>
      <router-link to="register" tag="a" class="btn btn-active-custom btn-right-custom bottom-0" > Kayıt Ol</router-link>
    </div>
    <div class="d-flex align-items-md-center justify-content-center h-100 custom-initial  m-auto" >
      <div class="w-100">
        <h3 class="form-title text-center m-md-4 my-3 pd-3 pd-md-0 d-none d-md-block">Kayıt Formu</h3>
        <div v-if="showLisansFailure" class="bg-danger text-white text-center p-2 my-2">
            Şirketiniz için aktif lisans bulunamamıştır. Lütfen sistem entegratörünüz ile iletişime geçiniz.
        </div>
        <div v-if="showMailFailure" class="bg-danger text-white text-center p-2 my-2">
            Bu mail adresi kullanımdadır. Şifremi unuttum ile yeni şifre talep edebilirsiniz.
        </div>
        <form>
          <validation-error v-bind:validation="$v.user" />
          <div class="input-group mb-4">
            <div class="input-group-prepend igp-custom"><i class="input-group-text igt-custom  fa fa-envelope"></i> </div>
            <input v-model="$v.user.$model" type="email" class="form-control fci-custom" id="user" placeholder="E-mail adresinizi giriniz." />
          </div>
          <validation-error v-bind:validation="$v.reuser" />
          <div class="input-group igp-custom mb-4">
            <div class="input-group-prepend igp-custom"><i class="input-group-text igt-custom fa fa-envelope"></i> </div>
            <input v-model="$v.reuser.$model"  class="form-control fci-custom" id="reuser" placeholder="E-mail adresinizi tekrar giriniz." />
          </div>
          <validation-error v-bind:validation="$v.password" />
          <div class="input-group igp-custom mb-4">
            <div class="input-group-prepend igp-custom"><i class="input-group-text igt-custom fa fa-key-modern"></i> </div>
            <input v-model="$v.password.$model"   class="form-control fci-custom" type="password" id="password" placeholder="Şifrenizi giriniz." />
          </div>
          <validation-error v-bind:validation="$v.terms" />
          <div class="form-group form-check d-flex justify-content-between fgl-custom"  >
            <label for="terms" class="form-check-label"    >
              <input v-model="$v.terms.$model"   class="form-check-input" type="checkbox" id="terms" > <a href="#" v-b-modal.terms>Kullanıcı Sözleşmesini</a>  okudum ve kabul ediyorum.
            </label>
          </div>
          <div class="d-flex justify-content-center">
            <button type="button"  v-on:click="handleRegister" class="btn btn-custom px-4 mx-3 "> Kayıt Ol</button>
          </div>
        </form>
      </div>
    </div>
    <b-modal id="terms" centered hide-footer title="Kullanım Koşulları" class="text-center" header-class="text-center">
      <Terms />
    </b-modal>
  
  </div>
</template>
<script>
  import { required,email,minLength,sameAs,numeric, not } from "vuelidate/lib/validators";
  import { mapActions } from "vuex";
  import ValidationError  from "./ValidationError";
  import Terms from "./Terms.vue"
  
  export default {
    components: { ValidationError, Terms },
    data: function() { return {
      user: "", 
      reuser: "",
      password: "",
      terms: false,
      showLisansFailure: false,
      showMailFailure: false,
      }
    },
    validations: {
      user: { required, email },
      reuser: { required, sameAsEmail: sameAs('user')},
      password: { required, minLength: minLength(8), notNumeric: not(numeric) },
      terms: {notAccepted: sameAs( () => true ) }
    },
    methods: {
      ...mapActions(["RegisterRequest","ActivateUser"]),
      handleRegister(){
        this.onChange();
        this.$v.$touch();
        if (!this.$v.$invalid) {
          this.RegisterRequest({ email: this.user, password: this.password})
          .then((result)=>{
          if (result == -1){
            this.showLisansFailure = true
          }else{
            this.showMailFailure = true
          } 
          })
        }
      },
      onChange(){
          this.showMailFailure = false;
          this.showLisansFailure = false;
      }   
    },
    beforeMount(){
      if(this.$route.query.token){
        this.ActivateUser({ token: this.$route.query.token})
      }
    }
  }
</script>
<style>
a.btn-active-custom {
  font-family: acumin-pro, sans-serif;
  color: #f26600;
  font-weight: 700;
  font-style: normal;
  font-size: 1.5rem;
  background-color: #f0f0f0;
  position: relative;
  top: -1em;
  border: none;
}
a.btn-passive-custom {
  font-family: acumin-pro, sans-serif;
  color: white;
  background: linear-gradient(
    to right,
    rgba(242, 102, 0, 1),
    rgba(242, 223, 0, 1)
  );
  font-weight: 700;
  font-style: normal;
  font-size: 1.5rem;
  background-size: calc(100% + 4px);
  position: relative;
  top: -1em;
}
a.btn-back-custom {
  font-family: acumin-pro, sans-serif;
  color: #f26600;
  font-weight: 700;
  font-style: normal;
  font-size: 1rem;
  box-shadow: 2px 2px 5px grey;
}
button.btn-custom {
  background: linear-gradient(
    to right,
    rgba(242, 102, 0, 1),
    rgba(242, 223, 0, 1)
  );
  font-family: acumin-pro, sans-serif;
  color: white;
  font-weight: 700;
  font-style: normal;
  font-size: 1.2rem;
  min-width: 10rem;
  background-size: calc(100% + 4px);
}

h3.form-title {
  font-family: acumin-pro, sans-serif;
  color: #797979;
  font-weight: 700;
  font-style: normal;
  font-size: 2rem;
}
input.fci-custom {
  background-color: #f0f0f0;
  border-left: 0 solid transparent;
}
i.igt-custom {
  color: #f26600;
  background-color: #f0f0f0;
}

div.igp-custom {
  border-right: 0;
}
div.fgl-custom {
  font-family: acumin-pro, sans-serif;
  color: #797979;
  font-weight: 700;
  font-style: normal;
  font-size: 1rem;
}

@media (max-width: 568px) {
h3.form-title {
  font-weight: 700;
  font-size: 1.5rem;
}
h3{
  padding-bottom: 1em;
  padding-top: 0.5em;
}
div.custom-initial{
  width: 75%;
}
}
@media (min-width: 568px) {
  h3.form-title {
  font-weight: 700;
  font-size: 2rem;
}
div.custom-initial{
  width: 50%;
}
}

</style>