<template>
  <div id="all-section" class="container-fluid h-100 d-flex flex-column px-0 px-sm-2">
    <div class="d-flex flex-column wrapper-custom mx-auto">
    <b-navbar class="px-0 px-sm-2" id="navibar">
      <b-navbar-brand class="d-none d-sm-block" :to="{ name: 'Companies'}"  > 
        <div class="d-flex ">
          <div>
            <img src="../assets/img/robot_logo.svg" class="text-white" alt="" />
          </div>
          <div class="d-flex flex-column text-white justify-content-center  align-content-center">
            <span class="logo-text lt-big-custom">ARDEO</span>
            <span class="logo-text lt-big-custom3">HUB</span>
          </div>
          <span class="logo-text lt-big-custom text-danger" v-if="isNotProduction">{{envType}}</span>
        </div>
      </b-navbar-brand>

      <router-link v-if="isDevice||isHelp||isProfile||isCompany||isBranch" :to="this.mobileReturnLink"><i class="fa fa-2x  fa-arrow-left d-block d-sm-none pl-2 lt-big-custom2 text-white" aria-hidden="true"></i></router-link>
      <b-navbar-brand class="d-block d-sm-none mx-auto" v-bind:class="mobileBannerClass" href="#" >
        <span class="logo-text lt-big-custom2 ">{{this.mobileBannerContent}}</span>
      </b-navbar-brand>
      
      <!-- Navbar dropdowns -->
      <b-navbar-nav class="ml-sm-auto" >
            <b-nav-item-dropdown no-caret  right text="Right align">
                <template  slot="button-content">
                  <i class='fa fa-cog fa-2x text-white d-none d-sm-block'></i>
                  <i class='fa fa-bars fa-2x text-white d-block d-sm-none'></i>
                </template>
                <b-dropdown-item :to="{ name: 'Profile'}">Profil</b-dropdown-item>
                <hr class="d-block d-sm-none" />
                <b-dropdown-item class="d-block d-sm-none" :to="{ name: 'Help'}">Yardım</b-dropdown-item>
                <hr />
                <b-dropdown-item v-on:click="quit">Çıkış</b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item  class="d-none d-sm-block" :to="{ name: 'Help'}" ><i class="fa fa-question-circle fa-2x  text-white"></i></b-nav-item>
    </b-navbar-nav>
    </b-navbar>
        <Companies v-if="isCompanies"    />
        <All v-if="isAll"    />
        <UserDevice v-else-if="isDevice"   />
        <Help v-else-if="isHelp" />
        <Profile v-else-if="isProfile" />
        <ChangePass v-else-if="isChangePass" />
    </div>
    <footer class="text-white text-right d-flex flex-grow-1 footer-custom " style="
    padding: 0.1rem;">
      <div class="d-none d-sm-block  ml-auto mt-auto ">Tüm hakları saklıdır.</div>
    </footer>
  </div>
</template>

<script>
// @ is an alias to /src
import All from '@/components/All.vue'
import UserDevice from '@/components/UserDevice.vue';
import Help from '@/components/Help.vue';
import Profile from '@/components/Profile.vue';
import ChangePass from '@/components/Password.vue'
import Companies from '@/components/Companies.vue'

import { mapGetters} from 'vuex'
export default {
  name: 'Home',
  components: {
    All,
    UserDevice,
    Help,
    Profile,
    ChangePass,
    Companies
  },
  data() {
    return {
      mobileBannerClass: "",
      mobileBannerContent: "",
      mobileReturnLink: "",
      envType: process.env.VUE_APP_TYPE,
    };
  },
  created(){
    this.contentArrangement()
  },
  computed:{
     isAll(){
       return (this.$route.name === "Company" || this.$route.name === "Branch")
     },
     isDevice(){
       return (this.$route.name === "userDeviceMenu")
     },
     isHelp(){
       return (this.$route.name === "Help")
     },
      isProfile(){
       return (this.$route.name === "Profile")
     },
     isChangePass(){
       return (this.$route.name === "ChangePassword")
     },
    isCompany(){
       return (this.$route.name === "Company")
     },
    isBranch(){
       return (this.$route.name === "Branch")
     },
     isCompanies(){
       return (this.$route.name === "Companies")
     },
     isNotProduction(){
       return (this.envType !== "Production")
     },
    ...mapGetters(['currentBranch'])
  },
  methods: {
    quit() {
      this.$store.commit('clearAuthenticated')
      this.$router.push("/login");
    },
    contentArrangement(){
      if (this.$route.name === "userDeviceMenu"){
        this.mobileBannerClass = "small-brand"
        this.mobileBannerContent = this.$route.params["user_device"] +" Verileri"
        this.mobileReturnLink = "/company/"+this.$route.params["companyname"]+"/branch/"+this.$route.params["branchname"]
      }else if(this.$route.name === "Help"){
        this.mobileBannerClass = "small-brand"
        this.mobileBannerContent = "Yardım"
        this.mobileReturnLink = "/"
      }else if(this.$route.name === "Profile"){
        this.mobileBannerClass = "small-brand"
        this.mobileBannerContent = "Profil"
        this.mobileReturnLink = "/"
      }else if(this.$route.name === "Company" || this.$route.name === "Branch" ){
        this.mobileBannerClass = "small-brand"
        this.mobileBannerContent = "Robot Listesi"
        this.mobileReturnLink = "/"
      }else if(this.$route.name === "Companies"){
        this.mobileBannerClass = "small-brand2"
        this.mobileBannerContent = "Şirket Listesi"
      }
        
    }
  },
  watch:{
    $route(){
      this.contentArrangement() 
    }
  }
}
</script>

<style>


  #all-section {
    background-image: linear-gradient(
        rgba(242, 102, 0, 0.3),
        rgba(0, 140, 242, 0.6)
      ),
      url(../assets/img/bacalarfit.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }
  a.small-brand {
    text-align: center;
  }
  a.small-brand2 {
    position: relative;
    left: 1.5rem;
  }
  span.logo-text {
  font-family: acumin-pro, sans-serif;
  color: white;
  font-weight: 400;
  font-style: normal;
  font-size: 1.2rem;
}

span.lt-big-custom {
  font-weight: 700;
  line-height: 60%;
}
span.lt-big-custom2 {
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 100%;
}
span.lt-big-custom3 {
  font-weight: 700;
}
div.card{
  background: #f0f0f0;
}
a.active{
  background: #f0f0f0 !important
}

@media (max-width: 568px) {
div.main-card-header-custom {
  padding: 0 1.25rem 1.25rem 1.25rem ;
}
a.nav-link{
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
    border-top-left-radius: 0rem !important;
  border-top-right-radius: 0rem !important;
}
div.main-card-header-custom {
  background-color: rgba(0, 140, 242, 1);
  border:none;
}
footer.footer-custom {
  background-color: rgba(0, 140, 242, 1);
}
div.main-card-custom {
    background-color: rgba(255, 0, 0, 0);
    border: 0px transparent solid;
    margin-top: 0;
    margin-left: 0rem;
    margin-right: 0rem;
  }
}

@media (min-width: 568px) {
div.main-card-header-custom {
  padding: 0.75rem 1.25rem 0.75rem 1.25rem ;
}
a.nav-link{
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0rem !important;
  border-bottom-right-radius: 0rem !important;
}
div.main-card-header-custom {
  background-color: rgba(0, 140, 242, 0.8);
}

div.main-card-custom {
    background-color: rgba(255, 0, 0, 0);
    border: 0px transparent solid;
    margin-top: 0;
    margin-left: calc(1rem);
    margin-right: calc(1rem);
  }
}

@media (min-width: 1280px){
  div.wrapper-custom{
    height: 68%;
    width: 80%;
  }
}
@media (max-width: 1280px){
  div.wrapper-custom{
    height: 100%;
    width: 100%;
  }
}
</style>