<template>
  <!--Ana Menudeki Duruş Listesi-->
  <div class="d-none d-lg-block CustomColumn">
    <h1 class="RecieptHeader Middle d-lg-block d-none text-center">Duruşlar <sup><i class="fa fa-info-circle fa-1" aria-hidden="true" id="stop-info" ></i></sup></h1> 
    
      <b-popover target="stop-info" triggers="click blur" placement="bottom">
        <template #title>Duruş Renk Kodları</template>
          <p><a class="dot red" />: Duruş Süresi >30dk </p>    
          <p><a class="dot orange" />:  30dk&#8805; Duruş Süresi >15dk </p>  
          <p><a class="dot yellow" />:  15dk&#8805; Duruş Süresi >5dk </p>  
          <p><a class="dot green" />:   5dk&#8805; Duruş Süresi </p>  
      </b-popover>

    <div class="my-4 productionTable">
      
      <b-table small borderless sticky-header no-border-collapse   :items="ListOfStops" :fields="fields" thead-class="hidden_header" >
        <template #cell(renk)="data">
          <div v-html="data.value"></div>
        </template>
      </b-table>
    </div>

  <!--Mobil Görünümdeki Duruş Listesi-->
    <b-modal id="modal-2" hide-footer style="overflow-y: auto"  > 
      <template #modal-title > {{getDateRange + ' Duruş Listesi '}} <sup><i class="fa fa-info-circle fa-1" aria-hidden="true" id="stop-info2" ></i></sup>  </template>

      <b-popover target="stop-info2" container="modal-2" triggers="click blur"  placement="bottom" >
        <template #title>Duruş Renk Kodları</template>
          <p><a class="dot red" />: Duruş Süresi >30dk </p>    
          <p><a class="dot orange" />:  30dk&#8805; Duruş Süresi >15dk </p>  
          <p><a class="dot yellow" />:  15dk&#8805; Duruş Süresi >5dk </p>  
          <p><a class="dot green" />:   5dk&#8805; Duruş Süresi </p>  
      </b-popover>

      <b-table small borderless  :items="ListOfStops" :fields="fields" thead-class="hidden_header"  >
        <template #cell(renk)="data">
          <div v-html="data.value"></div>
        </template>
      </b-table>
    </b-modal>
  </div>

</template>

<script>
export default {
  name: 'StopList',
   props: ['getDateRange','isDataLoaded','stopArray'],
  data() {return{
    fields:[ { key: 'Renk',  class: 'text-center' },{ key: 'Zaman',  class: 'text-center' },{ key: 'Sure',  class: 'text-center' },],
  }},
  methods:{
    secondsToHmsString( input){
      var result = ""
      var a = this.secondsToHms(input)
      if (a.hour > 0){
        result += a.hour + " sa "
      }
      if (a.minute > 0 ){
        result += a.minute + " dk "
      }
      if (a.seconds > 0 ){
        result += a.seconds + " sn "
      }
      return result
    },
    secondsToHms(d) {
      d = Number(d);
      var h = Math.floor(d / 3600);
      var m = Math.floor(d % 3600 / 60);
      var s = Math.floor(d % 3600 % 60);
      return {"hour":h,"minute" : m, "seconds": s}
    },
  },

  computed:{
    ListOfStops: function(){
      let i = 0
      var items = []
      if (this.isDataLoaded){
        for (const item of this.stopArray ){
          let timeDiff = (new Date(item.finish_date.replace(" ", "T"))-(new Date(item.start_date.replace(" ", "T"))))/1000
          let renk = ""
          if(timeDiff > 1800 ){
            renk = "<a class=\"dot red\" />"
          }else if(1800 >= timeDiff && timeDiff> 900){
            renk = "<a class=\"dot orange\" />"
          }else if(900 >= timeDiff && timeDiff>300){
            renk = "<a class=\"dot yellow\" />"
            
          }else if(300>=timeDiff && timeDiff >=0){
            renk = "<a class=\"dot green\" />"
          }
          items[i]= {"Renk":renk,"Zaman":new Date(item.start_date.replace(" ", "T")).toLocaleTimeString('tr-TR') + ' - ' + new Date(item.finish_date.replace(" ", "T")).toLocaleTimeString('tr-TR'),"Sure":this.secondsToHmsString(timeDiff)}
          i = i + 1
        }
      }
      return items
    }
  }
}
</script>

<style>

</style>