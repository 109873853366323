<template>
  <div id="login-section" class="container-fluid h-100">
    <div class="row h-100">
      <div id="banner" class="col-lg-5 d-lg-block position-relative" >
          <div class="d-flex flex-column justify-content-between h-100 ">
            <div class="d-flex m-auto m-sm-3">
              <div class="paddLogo">
              <img class="logo mr-sm-3" src="@/assets/img/robot_logo.svg" alt="robotiot">
              </div>
              <div class="d-flex flex-column text-white justify-content-center  align-content-center">
                <span class="logo-text-initial lt-big-custom-inital">ARDEO Hub</span>
                <span class="logo-text-initial">Platform</span>
              </div>
            </div>  
            <div>
                <h1 id="slogan" class="d-none d-md-block mb-4 text-danger" v-if="isNotProduction">{{envType}}</h1>
                <h1 id="slogan" class="d-none d-md-block mb-4">Her Nerede Olursanız Olun Üretim Hattınız Parmaklarınızın Ucunda </h1>
            </div>
          </div>
      </div>
      <div class="col-lg-7 ">
        <Login v-if="$route.path === '/login'"   />
        <Register v-if="$route.path === '/register'"   />
        <ResetPassword v-if="$route.path === '/resetPassword'" />
        
      </div>
    </div>
  </div>
</template>

<script>
import Login from '@/components/Login.vue'
import Register from '@/components/Register.vue'
import ResetPassword from '@/components/ChangePassword.vue'

export default {
  name: 'Initial',
  components: {
    Login,
    Register,
    ResetPassword,
  },
  data: function() { return {
      envType: process.env.VUE_APP_TYPE,
      }
    },
  computed:{
     isNotProduction(){
       return (this.envType !== "Production")
     },
  }
}
</script>

<style>
.paddLogo{
  padding-right: 2em ;
}
@media (max-width: 568px) {
  #navibar {
    background-color: rgba(242, 102, 0, 0.9);
  }
  #banner {
    background-image: linear-gradient(
        to bottom,
        rgba(242, 102, 0, 0.7),
        #008cf299
      ),
      url("../assets/img/smloginback.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 35% center;
  }
  .logo {
    width: 150%;
  }
}

@media (min-width: 568px) {
  #banner {
    background-image: linear-gradient(
        to bottom,
        rgba(242, 102, 0, 0.3),
        rgba(0, 140, 242, 0.6)
      ),
      url("../assets/img/lgloginback1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 35% center;
  }
  .logo {
    width: 120%;
  }
}

#slogan {
  font-family: acumin-pro, sans-serif;
  color: white;
  font-weight: 700;
  font-style: normal;
  font-size: 1.5rem;
}
.btn-active-custom {
  font-family: acumin-pro, sans-serif;
  color: #f26600;
  font-weight: 700;
  font-style: normal;
  font-size: 1.5rem;
  background-color: white;
}
.btn-passive-custom {
  font-family: acumin-pro, sans-serif;
  color: white;
  background: linear-gradient(
    to right,
    rgba(242, 102, 0, 1),
    rgba(242, 223, 0, 1)
  );
  font-weight: 700;
  font-style: normal;
  font-size: 1.5rem;
  background-size: calc(100% + 4px);
}
.overlay-class {
  bottom: -2rem;
  left: calc((100vw - 238px) / 2);
  z-index: 999;
}
.btn-right-custom {
  border-radius: 0 1rem 1rem 0;
  border-left: 0 solid transparent;
  border: 1px #ced4da solid;
}
.btn-left-custom {
  border-radius: 1rem 0 0 1rem;
  border: 1px #ced4da solid;
  border-right: 0;
}

.logo-text-initial {
  font-family: acumin-pro, sans-serif;
  color: white;
  font-weight: 400;
  font-style: normal;
  font-size: 1.6rem;
}
.lt-big-custom-initial {
  font-weight: 700;
  line-height: 60%;
}

#login-section{
  background-color: #f0f0f0;
}
</style>