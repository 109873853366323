<template>
  <svg class="svgGraph" width="21em" height="21em" viewbox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" version="1.1" >
    <circle cx="50%" cy="50%" r="7em" stroke="#1BD170" stroke-width="1.1rem" fill="None" />
    <g class="summaryRotate">
      <circle cx="50%" cy="50%" r="7em" stroke="#F63E3E" stroke-width="1.1rem" v-bind:stroke-dasharray="workP.stopP"  fill="None" />
      <circle cx="50%" cy="50%" r="7em" stroke="#797979" stroke-width="1.1rem" v-bind:stroke-dasharray="workP.unknownP"  fill="None" />
    </g>
    <text text-anchor="middle" x="50%" y="37%" class="TextInGraphBig" fill="#1BD170" >%{{Math.floor(currentShiftInfo.work_efficiency)}}</text>
    <text text-anchor="middle" x="50%" y="45%" class="TextInGraph" fill="#1BD170" >Çalışma Verimliliği</text>
    <text  text-anchor="middle" x="35%" y="60%" class="TextInGraphBig2" fill="#707070" >{{secondsToHms(currentShiftInfo.production_time).hour}}</text>
    <text  text-anchor="middle" x="45%" y="54%" class="TextInGraphMini" fill="#707070" >Saat</text>
    <text  text-anchor="middle" x="57%" y="60%" class="TextInGraphBig2" fill="#707070" >{{secondsToHms(currentShiftInfo.production_time).minute}}</text>
    <text  text-anchor="middle" x="69%" y="54%" class="TextInGraphMini" fill="#707070" >Dakika</text>
    <text text-anchor="middle" x="50%" y="70%" class="TextInGraph" fill="#707070" >Çalışma Süresi</text>
  </svg>
</template>

<script>
export default {
  name: 'WorkSummarySVG',
   props: ['currentShiftInfo','',''],
  data() {return{
    
  }},
  methods:{ 
   secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);
    return {"hour":h,"minute" : m, "seconds": s}
    },
  },
  computed:{
    workP: function(){
      let vInfo = this.currentShiftInfo
      let uP = vInfo.unknown_time / vInfo.total_time
      let sP = vInfo.stop_time / vInfo.total_time
      return {'unknownP':uP * 44 +"em 44em",'stopP':(sP+uP)*44+"em 44em"}
    }
  }
}
</script>

<style>

</style>