import Axios from "axios";
import store from '../store'

const baseUrl = process.env.VUE_APP_API_SERVER;
const refreshToken = `${baseUrl}/api/v1/token/refresh`;
export default{
  checkAccessToken(){ 
    if (store.state.auth.access){
      if(this.isTokenValid(store.state.auth.access)){
        return true
      }
    }else{
      if(localStorage.rememberMe == "true"){
        if(localStorage.access){
          if (this.isTokenValid(localStorage.access)){
            store.commit("LoadJwt",localStorage.access)
            return true
          }else{
            return false
          }
        }else{
          return false
        }
      }else{
        if(sessionStorage.access){
          if (this.isTokenValid(sessionStorage.access)){
            store.commit("LoadJwt",sessionStorage.access)
            return true
          }else{
            return false
          }
        }else{
          return false
        }
      } 
    }
  },
  getRefreshToken(){
    if(localStorage.rememberMe == "true"){
      if(typeof localStorage.refresh == 'undefined' || localStorage.getItem("refresh") == null  || localStorage.getItem("refresh") == "undefined" ){
        return -1
      }else{
        return localStorage.refresh
      }
    }else{
      if(sessionStorage.refresh){
        return sessionStorage.refresh
      }else{
        return -1
      }
    }
  },
  parseJwt (token) {
    console.log("a1")
    console.log(token)
    console.log("a2")
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(Buffer.from(base64, "base64").toString("ascii").split("").map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    console.log(JSON.parse(jsonPayload)["user_id"])
    return JSON.parse(jsonPayload);
  },
  isTokenValid(jwt) {
    const jwtPayload = this.parseJwt(jwt)
    if (jwtPayload.exp < Date.now()/1000) {
      return false
    }else{
      return true
    }
  },
  isRefreshValid(){
    const refreshJwt = this.getRefreshToken()
    if (refreshJwt == -1){
      return false
    }else{
      const jwtPayload = this.parseJwt(refreshJwt)
      if (jwtPayload.exp < Date.now()/1000) {
        return false
      }else{
        return true
      }
    }
  },
  async renewAccessToken(){
    if(!this.checkAccessToken()){
      if(this.isRefreshValid()){
        let body = {refresh:this.getRefreshToken()}
        try{
          let response =  await Axios.post(refreshToken,body)
          store.commit("setAuthenticated",response.data)
          return true
        }catch{
          store.commit("clearAuthenticated")
          return false
        }
      }else{
        store.commit("clearAuthenticated")
        return false
      }
    }else{
      return true
    }
  }
}
