<template>
  <div class="d-flex justify-content-center align-items-center flex-column" id="main">
    <div class="d-flex justify-content-center align-items-center">
    <h1 class="mr-3 pr-3 align-top border-right inline-block align-content-center">404</h1>
    <div class="inline-block align-middle">
        <h2 class="font-weight-normal lead" id="desc">Sayfa bulunamadı!</h2>  
    </div>
    </div>
    <div class="d-block">
      <router-link to="/"> Ana sayfaya geri dön</router-link>
    </div>
    
  </div>

</template>

<script>
export default {

}
</script>

<style>
#main {
    height: 100vh;
}
</style>