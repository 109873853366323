<template>
  <div class="screens animate m-auto">
    <div class="screen " v-bind:class="{'active': currentProductId === 0 }">
      <WorkSummarySVG :currentShiftInfo=currentShiftInfo />
    </div>
    <div class="screen " v-for="(item,index) in currentShiftInfo.products" v-bind:key="index+1" v-bind:class="{'active': currentProductId === index+1 }">
      <ProductSVG :productColors=productColors :item="item" :index="index" />
    </div>
  </div>
</template>

<script>
import WorkSummarySVG from '@/components/UserDevice/GraphScreens/WorkSummarySVG.vue'
import ProductSVG from '@/components/UserDevice/GraphScreens/ProductSVG.vue'

export default {
  name: 'GraphScreens',
  props: ['currentShiftInfo','productColors','currentProductId'],
  components: {
    WorkSummarySVG,
    ProductSVG
  },
  data() {return{
    
  }},
  methods:{ 

  },
  computed:{

  }
}
</script>

<style>
.screens{
  flex:1;
  align-self: stretch;
  order:2;
  height: 17em;
  width: 18em;
  position:relative;
  overflow: hidden;
}

.screen{
  position: absolute;
  opacity: 0;
  transform: translateX(-72px);
  top:0;
  left: 0;
  right: 0;

}
.screen.active{
  order:2;
  opacity: 1;
  transform: translateX(0) scale(1);
  position: relative;
}

.screen.active ~.screen{
  transform: translateX(72px);
}

.prevScreen, .nextScreen{
  background: none;
  border: None;
  color: rgba(112,112,112, 0.50);
  cursor: pointer;
  outline: none;
  z-index: 1000;
  font-size: 3em;

  padding: 0;
}
.prevScreen{
  order:1;
}
.nextScreen{
  order:3;
}

.svgGraph{
  margin-top: -2.8em;
  margin-bottom: -4em;
  margin-left: -1em;
  margin-right: -1em;
}

.graphRotate{
  transform-origin: center center;
  transform: rotate(-225deg);
}

.TextInGraph{
  font-size: 1rem;
}
.TextInGraphBig{
  font-weight: 600;
  font-size: 2.5rem;
}
.TextInGraphBig2{
  font-weight: 400;
  font-size: 2.2rem;
}

.TextInGraphMini{
  font-size: 0.8rem;
  font-weight: 600;
}

.summaryRotate{
  transform-origin: center center;
  transform: rotate(-90deg);
}

@media (max-width: 960px) {
  
  div.main-card-body-custom {
  background-color: rgba(240, 240, 240, 0.8);
  height: calc(100vh - 200px);
  overflow-y: auto;
}
}
@media (min-width: 960px) {
  .screen{
    margin-top: 0rem;
  }
  .screen{
    margin-top: 1rem;
  }
}
</style>