<template>
<div id="app" class="container-fluid h-100 m-0 p-0">
  <router-view />
</div>
</template>

<script>
  export default {
    name: 'App',
    metaInfo: {
      title: process.env.VUE_APP_TITLE,
    }
  }
</script>