<template>
<div class="card text-center main-card-custom flex-grow-1 d-flex flex-sm-column flex-column-reverse flex-column-reverse flex-lg-row">
  
  <!--Menu-->
  <div class="DeviceNavBar" v-if="(isDataLoaded)">
    <DeviceNavBar @changePageinChild="changePage" :currentShiftId=currentShiftId :baska=baska />
  </div>
  
  <div id="parent" class="card-body main-card-body-custom py-0 d-flex justify-content-center ParentCustom" >
    <!--01.Ana Menu-->
    <div id="child"  class="h-100 my-auto DeviceCustom d-flex flex-column flex-grow-1" v-if="(isDataLoaded)">
      <!--01.01.Header-->
      <div>
        <h1 class="RecieptHeader Big mt-3">{{(!MobileScreen?DeviceName +" ":"") + (baska?"Başka Gün":tabs[currentShiftId]+" Vardiya")}}</h1>
        <h1 class="RecieptHeader Small">{{getDateRange}} </h1>
      </div>
      <!--01.02.[Çalışma/Ürün Grafikler - Çalışma/Ürün Listeleri - Duruşlar]-->
      <div class="d-lg-flex justify-content-around CustomWrapper">
        <!--01.02.01.[Çalışma/Ürün Grafikler]-->
        <div class="CustomColumn">
          <!--Grafiklerin Basligi PC icin-->
          <h1 class="RecieptHeader Middle d-lg-block d-none text-center" >{{currentProductId === 0?"Çalışma Özeti":nData.shifts[currentShiftId].products[currentProductId-1].name + " Detayı" }}</h1>

          <div id="RecieptSummary">
            <div id="recieptGraph" class="d-flex"  @touchstart="touchStart" >
              <!--Grafikler-->
              <GraphScreens :currentShiftInfo=nData.shifts[currentShiftId] :productColors=productColors :currentProductId=currentProductId />
              <!--Grafik Navigasyon Butonlari-->
              <button class="prevScreen" v-on:click="PrevProd"><i class="fa fa-angle-left fa-3" aria-hidden="true"></i></button>
              <button class="nextScreen" v-on:click="NextProd"><i class="fa fa-angle-right fa-3" aria-hidden="true"></i></button>
            </div>
          </div>
          <!--Grafiklerin Basligi Mobil icin-->
          <h1 class="RecieptHeader Middle d-block d-lg-none">{{currentProductId === 0?"Çalışma Özeti":nData.shifts[currentShiftId].products[currentProductId-1].name + " Detayı" }}</h1>
          <!--Grafik Navigasyon Bari-->
          <div id="Action Bar" class="d-flex">
            <!--Detayli Grafik Acma Butonu-->
            <i v-b-modal.modal-1 class="fa fa-bar-chart fa-2x grayicon d-block" aria-hidden="true"></i>
            <!--Grafik Sayfa Durumu Gostergesi-->
            <div id="GraphPagination" class="mx-auto" >
              <a class="dot " v-bind:class="{'active': currentProductId === 0 }" />
              <a class="dot " v-for="(item,index) in nData.shifts[currentShiftId].products" v-bind:key="index"  v-bind:class="{'active': currentProductId === index+1 }"  />
            </div>
            <!--Duruş Listesi Açma Butonu(Mobil için)-->
            <i v-b-modal.modal-2 class="fa fa-list-ol fa-2x grayicon d-lg-none d-block" aria-hidden="true"></i>
          </div>
        </div>
        <!--01.02.02.[Çalışma/Ürün Listeleri]-->
        <div class="CustomColumn">
          <h1 class="RecieptHeader Middle d-lg-block d-none text-center" v-if="currentProductId === 0">Çalışma Detayı</h1>
          <h1 class="RecieptHeader Middle d-lg-block d-none text-center" v-else> Üretim Detayı</h1>
          <div id="ProductionCount" class="my-4 productionTable mx-auto" v-if="currentProductId>0">
            <b-table small borderless  sticky-header no-border-collapse :items="productList" thead-class="hidden_header" ></b-table>
          </div>
          <div id="ShiftSummary" class="my-4 productionTable mx-auto"  v-else>
            <b-table small borderless  :items="dynSummary" thead-class="hidden_header" show-empty></b-table>
          </div>
        </div>
        <!--01.02.03.[Duruş Listesi] -->
        <StopList :getDateRange=getDateRange :isDataLoaded=isDataLoaded :stopArray=stopArray />
      </div>
      <!-- Modal1 -->
        <GraphModal1 :productColors=productColors :WorkBars4G=WorkBars4G :ProdBars4G=ProdBars4G />
      <!-- Modal3 -->
        <QueryOtherDay @buildSSEconnectioninChild="buildSSEconnection"  />
    </div>

    <!--02.Yükleniyor Ara Yüzü -->
    <div v-else class="m-auto">
      <h1 class="RecieptHeader Big">Yükleniyor...</h1> 
      <i class="fa fa-refresh fa-spin fa-3x fa-fw grayicon" ></i>
      <span class="sr-only">Loading...</span>
    </div>
    <!--03.EM boyutunu almak icin gizli bir field-->
    <div id="em"></div>
  </div>
</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import DeviceNavBar from '@/components/UserDevice/DeviceNavBar.vue'
import QueryOtherDay from '@/components/UserDevice/QueryOtherDay.vue'
import GraphModal1 from '@/components/UserDevice/GraphModal1.vue'
import StopList from '@/components/UserDevice/StopList.vue'
import GraphScreens from '@/components/UserDevice/GraphScreens.vue'
import tokenOps from '../scripts/slientRefresh'
export default {
  name:'UserDevice',
  components: {
    DeviceNavBar,
    QueryOtherDay,
    GraphModal1,
    StopList,
    GraphScreens,
  },
  data() {
    return{
      client: this.$sse.SSEClient ,
      deviceUrl: process.env.VUE_APP_API_SERVER +"/api/v1/user-devices/",
      currentShiftId:0,
      currentProductId:0,
      sdata:[],
      productColors: ['#FF0077','#54E856','#1768EB','#E8C882'],
      trigger_return: "",
      barWidth: 0,
      timeOutVar: "",
      baska: false,
      tabs: ['Güncel', 'Bir Önceki', 'İki Önceki'],
      tabsLogo: ['fa-clock-o','fa-angle-left','fa-angle-double-left']
      }
  },
  created(){
    if (this.companies == "" || this.companies == undefined ){
      this.getBusiness()
      .then(result => {
        if(result == 1){
          if(this.currentCompany === -1 || this.currentBranch === -1){
            this.$router.push('/404')
        }else{
          this.buildSSEconnection("currentShifts","")
        }
      }
    })
    }

  },
  mounted(){
    
    
  },
  beforeDestroy(){
    this.stop_sse()
    clearTimeout(this.timeOutVar)
  },
  methods: {
    ...mapActions([
      'tokenRenewal'
    ]),
    async trigger4SSE(triggerType, messageBody){
      const CurrentShiftsUrl=this.deviceUrl+this.getDevice["id"]+"/shifts/trigger_shifts"
      const OtherDayShifts=this.deviceUrl+this.getDevice["id"]+"/shifts/trigger_shifts_tf"
      tokenOps.renewAccessToken()
      .then((result)=>{
        if (result==true){
          if(triggerType == "currentShifts"){
            this.authenticatedAxios.get(CurrentShiftsUrl)
            .then((result)=>{
              if (status == 200){
                this.baska = false
                this.trigger_return = result.data}})
          }else if(triggerType == "otherDayShifts"){
            this.sdata = []
            this.authenticatedAxios.post(OtherDayShifts,messageBody)
            .then((result)=>{
              if (status == 200){
              this.trigger_return = result.data}})
          }
        }else{
          this.$router.push("/login");
        }  
      })},

    buildSSEconnection(triggerType,messageBody){
      let  ConnectionUrl = ""
      if(triggerType == "currentShifts"){
        ConnectionUrl = process.env.VUE_APP_API_SERVER+"/channels/shifts-"+this.getDevice["id"]+"/events/"}
      else if(triggerType == "otherDayShifts"){
        ConnectionUrl = process.env.VUE_APP_API_SERVER+"/channels/shifts_tf-"+this.getDevice["id"]+"/events/"
        this.currentShiftId = 0
        this.baska = true
      }
      this.checkTimeOut()
      if (this.client !== undefined){
        this.client.disconnect()
      }
      this.client = this.$sse.create({
        format: "json",
        polyfill: true, 
        url: ConnectionUrl, 
        withCredentials: true, 
        polyfillOptions:{headers: {"Authorization": "Bearer "+this.jwt,}} ,
        forcePolyfill:true})

      this.client.connect()
      this.trigger4SSE(triggerType,messageBody)
      this.client.on('error', (e) => {
        console.error('lost connection or failed to parse!', e);
      });
      this.client.on('message', (m) => {
        clearTimeout(this.timeOutVar)
        console.log(m)
        this.sdata = m
      });

    },
    
    checkTimeOut() {
      this.timeOutVar=setTimeout(() => {
        if (this.sdata.length === 0){
          if (this.client !== undefined){
            this.client.disconnect()
          }
          this.$router.push({ name: 'Company', query: { result: 'fail2get' } }) 
        }
      }, 10000);
    },

    changePage(int){
      if (Object.keys(this.nData.shifts).length > 1){
        this.currentShiftId = int
        this.currentProductId = 0
      }else{
        this.sdata =[]
        if (this.client !== undefined){
          this.client.disconnect()
        }
        this.buildSSEconnection("currentShifts")
        this.baska = false
        this.currentShiftId = int
        this.currentProductId = 0
      }
    },




    NextProd(){
      if (screen.width < 960) {
          return;
        }
      if (this.currentProductId<this.indexMax){
        this.currentProductId += 1 
      }
        
    }
    ,
    PrevProd(){
      if (screen.width < 960) {
          return;
        }
      if (this.currentProductId >0){
        this.currentProductId -= 1 
      }
    },
   secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);
    return {"hour":h,"minute" : m, "seconds": s}
    },

  stop_sse(){

    if (this.client !== undefined){
      this.client.disconnect()
    }
  },
  touchStart (touchEvent) {
    if (screen.width > 960) {
          return;
        }
        if (touchEvent.changedTouches.length !== 1) { // We only care if one finger is used
          return;
        }
        const posXStart = touchEvent.changedTouches[0].clientX;
        addEventListener('touchend', (touchEvent) => this.touchEnd(touchEvent, posXStart), {once: true});
      },
  touchEnd (touchEvent, posXStart) {
        if (touchEvent.changedTouches.length !== 1) { // We only care if one finger is used
          return;
        }
        const posXEnd = touchEvent.changedTouches[0].clientX;
        if ( (posXEnd - posXStart) < - 50) { //next
          if (this.currentProductId<this.indexMax){
            this.currentProductId += 1 
          }
        } else if ( (posXEnd - posXStart) > 50) { //prev
          if (this.currentProductId >0){
            this.currentProductId -= 1 
          }
        }
      },
  clearsData(){
      this.sdata = []
      if (this.client !== undefined){
          this.client.disconnect()
      }
    },
  updatesData(dataRecieved){

      this.sdata = JSON.parse(dataRecieved)
    },

    ...mapActions([
      'getBusiness'
    ]),
  },
  computed:{
    ...mapState({jwt: state => state.auth.access}, {companies: state =>state.companies}),
    ...mapGetters([
      'authenticatedAxios', 'currentCompany'
     ]),
    isDataLoaded(){
      if (Object.keys(this.nData.shifts).length > 0){
        return true
      }else{
        return false
      }
    },
    getDateRange(){
      let start_date = this.nData.shifts[this.currentShiftId].start_date
      let stop_date = this.nData.shifts[this.currentShiftId].finish_date
      let startDate = new Date(start_date.replace(" ", "T"))
      let stopDate = new Date(stop_date.replace(" ", "T"))
      if ( startDate.getFullYear() === stopDate.getFullYear() &&
           startDate.getMonth() === stopDate.getMonth() &&
           startDate.getDate() === stopDate.getDate()){
        return startDate.toLocaleDateString(["tr-tr"], {day: '2-digit', month:'short'})+ " ("+startDate.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}) +" - "+stopDate.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})+ ") "
      } else{
        return startDate.toLocaleDateString(["tr-tr"], {day: '2-digit', month:'short'})+ " ("+startDate.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'}) +") - "+stopDate.toLocaleDateString(["tr-tr"], {day: '2-digit', month:'short'})+ " ("+stopDate.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})+ ") "
      }
    },
    getDevice(){
      return this.$store.getters.findDeviceByName(this.$route.params["branchname"],this.$route.params["departmentname"],this.$route.params["user_device"])
    },
    nData: function(){
      if (Array.isArray(this.sdata)){
        return this.sdata.reduce((result,item)=>{
          result.shifts[item.eid] = item
        return result;
      },{shifts:{}})
      }
      else{
        return {shifts:{}}
      }
      },
    productList: function(){
      var i = 0
      var items = []
      for (const element of this.nData.shifts[this.currentShiftId].products){
        items[i] = { ParçaAdı: element.name, Adet: element.count, Birim:'Adet'}
        i += 1
      }
      return items
    },
    indexMax:function(){
      if (this.isDataLoaded){
        return this.nData.shifts[this.currentShiftId].products.length
      }else{
        return 1
      }
    },
    dynSummary:function(){
      var items = []
      var total = this.secondsToHms(this.nData.shifts[this.currentShiftId].total_time)
      var work = this.secondsToHms(this.nData.shifts[this.currentShiftId].production_time)
      var stop = this.secondsToHms(this.nData.shifts[this.currentShiftId].stop_time)
      var unknown = this.secondsToHms(this.nData.shifts[this.currentShiftId].unknown_time)
      items[0]= {"Tur":"Toplam Süre","Saat":total.hour+ " Saat","Dakika":total.minute+ " Dakika" }
      items[1]= {"Tur":"Çalışma","Saat":work.hour+ " Saat","Dakika":work.minute+ " Dakika" }
      items[2]= {"Tur":"Duruş","Saat":stop.hour+ " Saat","Dakika":stop.minute+ " Dakika" }
      items[3]= {"Tur":"Bilinmeyen","Saat":unknown.hour+ " Saat","Dakika":unknown.minute+ " Dakika" }
      items[4]= {"Tur":"","Saat":"","Dakika":"" }
      items[5]= {"Tur":"Toplam Acile Basılma","Saat":"","Dakika":this.nData.shifts[this.currentShiftId].total_emg_stop+" Adet" }
      items[6]= {"Tur":"Toplam Kapı Açılma","Saat":"","Dakika":this.nData.shifts[this.currentShiftId].total_door_open+" Adet" }
      return items
    },
    stops2print: function(){
      if (this.isDataLoaded){
        let msStops = this.nData.shifts[this.currentShiftId].stop_time
        let seconds = (msStops / 1000).toFixed(0);
        let minutes = (msStops / (1000 * 60)).toFixed(0);
        let hours = (msStops / (1000 * 60 * 60)).toFixed(0);
        return {'hours':hours,'minutes':minutes,'seconds':seconds}
      } else{
        return ""
      }
    },
    emSize: function(){
      return document.getElementById('em').clientWidth;
    },
    MaxVal: function(){
      let partialshifts = this.nData.shifts[this.currentShiftId].partial_shifts
      let MaxEl = 0
      for (const element of partialshifts){
          if (parseInt(element.production_time) > MaxEl){
            MaxEl = parseInt(element.production_time)
          }
          if(parseInt(element.stop_time) > MaxEl){
            MaxEl = parseInt(element.stop_time)
          }
          if (parseInt(element.unkown_time) > MaxEl){
            MaxEl = parseInt(element.unkown_time)
          }
      }
      
      return MaxEl
    },
    Barlegends:function(){
      let legends= {}
      let l=[]
      if (this.isDataLoaded){
        let startHeight = this.emSize*19
        let maxHeight = (this.emSize*17)
        let step = maxHeight/4
        let current = startHeight
        for (let i =0;i<3;i++){
          current = current - step
          l[i] = {id:i,y:current}
        }
        legends = {lines:l}
      }
      return legends
    },
    Bars: function(){
      let bars= []
      if (this.isDataLoaded){
      let partialshifts = this.nData.shifts[this.currentShiftId].partial_shifts
      const wP = "4%"
      const colorList = ["#797979","#F63E3E","#1BD170"]
      let stepSize = (this.barWidth-this.emSize)*0.9 / (16)
      let maxHeight = this.emSize*17
      let startHeight = this.emSize*19
      let currentStep= this.emSize*1.1
      
      let i =0
      for (const element of partialshifts){
        const maxDur = (new Date(element.finish_date.replace(" ", "T"))-(new Date(element.start_date.replace(" ", "T"))))/1000
        let percProd = (element.production_time*maxHeight / maxDur)
        let percStop = (element.stop_time*maxHeight / maxDur)
        let percUnkown = (element.unknown_time*maxHeight / maxDur)
        let Prod = [percProd+percStop+percUnkown,percProd+percStop,percProd]
        for (let j=0;j<3;j++){
          bars[i] = {id:i,h:Prod[j], w:wP, x:currentStep, y:(startHeight-Prod[j]), c:colorList[j]}
          i += 1
        }
        currentStep = currentStep + stepSize
      }
     }
     return bars
    },
    WorkBars4G:function(){
      let chartData= []
      let partialshifts = this.nData.shifts[this.currentShiftId].partial_shifts
      chartData[0]= ["Zaman","Üretim","Duruş","Bilinmeyen"]
      let i =1
      for (const element of partialshifts){
        chartData[i]= [(new Date(element.start_date.replace(" ", "T"))),parseFloat((parseInt(element.production_time)/60).toFixed(2)),parseFloat((parseInt(element.stop_time)/60).toFixed(2)),parseFloat((parseInt(element.unknown_time)/60).toFixed(2))]
        i += 1
      }
      return chartData
    },
    ProdBars4G:function(){
      let productList = this.nData.shifts[this.currentShiftId].products
      let partialshifts = this.nData.shifts[this.currentShiftId].partial_shifts
      let chartX= []
      let chartData= []
      for (const element of productList){
        chartX.push(element.name)
      }
      chartData[0]= ["Zaman", ...chartX]
      let i =1
      for (const element of partialshifts){
        let j = 0
        let partialProduct = []
        let notMatch = true
        for(const element2 of chartX){
          notMatch = true
          for (const element3 of element.partial_products){
            if (element2 == element3.name){
              partialProduct[j] = parseInt(element3.count)
              notMatch = false
              continue; 
            }
          }
          if( notMatch == true){
            partialProduct[j] = 0
          }
          j += 1
        }
        chartData[i]= [(new Date(element.start_date.replace(" ", "T"))),...partialProduct]
        i += 1
      }
      return chartData
    },
    calcPoints: function(){
      let partialshifts = this.nData.shifts[this.currentShiftId].partial_shifts
      var prod_points = ""
      var stop_points = ""
      var unk_points = ""
      let steps = this.emSize*19 / (partialshifts.length-1) 
      var xstart = this.emSize
      for (const element of partialshifts){
        var percProd = element.production_time / this.MaxVal
        var percStop = element.stop_time / this.MaxVal
        var percUnkown = element.unknown_time / this.MaxVal
        var ystart1 = this.emSize*19-this.emSize*18*percProd
        var ystart2 = this.emSize*19-this.emSize*18*percStop
        var ystart3 = this.emSize*19-this.emSize*18*percUnkown
        var prod_point =  xstart.toString() +","+ ystart1.toString()+" "
        var stop_point =  xstart.toString() +","+ ystart2.toString()+" "
        var unk_point =  xstart.toString() +","+ ystart3.toString()+" "
        xstart = xstart+steps
        
        prod_points += prod_point
        stop_points += stop_point
        unk_points += unk_point
      }
    return  {"prod_points":prod_points, "stop_points":stop_points, "unk_points":unk_points}
    },
    stopArray: function(){
      if (this.isDataLoaded){
        return this.nData.shifts[this.currentShiftId].stops 
      }else{
        return []
      }
    },
    DeviceName: function(){
      return this.$route.params["user_device"]
    },
    MobileScreen: function(){
      return ((window.innerWidth<960)?true:false)
    }
  }
}
</script>

<style>
  div.ParentCustom{
  padding:0rem;
  overflow: hidden;
  position: relative;
  width: 100%;
    height: 100%;
  }
  div.DeviceCustom{
  padding-left: 1rem;
  padding-right: 2rem;
  overflow-y: auto;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: -1rem;
}

  i.fa.active{color: #707070;}
  i.fa.passive{color: #F0f0f0;}
  .grayicon{color: #707070;}
  .gwhiteicon{color: #F0f0f0;}
  .whiteicon{color: #FFFFFF;}

  h1.RecieptHeader,.TextInGraph,.TextInGraphBig,.TextInGraphBig2, .recieptName,.recieptName2 {
    color: #707070;
    font-weight: 700;
    font-style: normal;
    text-align: center;
  }
  h1.RecieptHeader.Big{
    font-size: 2.2rem;
  }
  h1.RecieptHeader.Middle{
    font-size: 1.5rem;
  }
  h1.RecieptHeader.Small{
    font-size: 1.2rem;
  }

  #GraphPagination{
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;
  }

  .dot{
    border:1px solid #707070;
    height: 1em;
    width: 1em;
    border-radius: 50%;
    transform: scale(0.7);
    margin: 0 0.5em;
    display: inline-block;
  }

  #GraphPagination a.active{
    background: #707070 !important;
    transform: scale(1);
  }
  a.dot.red{
    background: #F63E3E !important;
    transform: scale(1);
  }
  a.dot.yellow{
    background: #F8D731 !important;
    transform: scale(1);
  }
  a.dot.orange{
    background: #F78B38 !important;
    transform: scale(1);
  }
  a.dot.green{
    background: #1BD170 !important;
    transform: scale(1);
  }

.grid {
  stroke: #707070;
  stroke-dasharray: 0;
  stroke-width: 1;
}
.hidden_header {
  display: none;
}
.productionTable{
  border: 2px solid;
  border-color: rgba(111,111,111,0.2) ;
  width: 20em;
  height: 240px;
}
div.b-table-sticky-header{
  max-height: 230px !important;
}


#em {
	width: 1em;
	visibility: hidden;
	pointer-events: none;
	position: absolute;
}

@media (max-width: 960px) {
  
  div.main-card-body-custom {
  background-color: rgba(240, 240, 240, 0.8);
  height: calc(100vh - 200px);
  overflow-y: auto;
  }
  div.CustomWrapper{
    margin-top: 0rem;
  }
}
@media (min-width: 960px) {
  div.main-card-body-custom {
    background-color: rgba(240, 240, 240, 0.8);  
    overflow-y: auto;
    
  }
  div.CustomColumn{
    width: 21rem;
  }
  div.CustomWrapper{
    margin-top: 1.5rem;
  }
}
</style>