import Axios from "axios";
import Vue from 'vue';
import router from '../router';
import tokenOps from '../scripts/slientRefresh';
const baseUrl = process.env.VUE_APP_API_SERVER;
const authUrl = `${baseUrl}/api/v1/token`;
const renewalRequestUrl = `${baseUrl}/api/v1/user/password/reset`;
const renewalRequest = `${baseUrl}/api/v1/user/password/reset_confirm`;
const registerUrl = `${baseUrl}/api/v1/user/register`;
export default{

  state:{
    authenticated: false,
    access: "",
    refresh: "",
    user_id:""
  },
  getters:{
    authenticatedAxios(state){
        return Axios.create({
          headers:{
            "Authorization": `Bearer ${state.access}`
          }
      });
    }
  },

  mutations:{
    setAuthenticated(state, header){
      state.authenticated = true
      for (const input of Object.keys(header)) {
        if (input == "access"){
          Vue.set(state,"user_id",tokenOps.parseJwt(header[input])["user_id"])
        }
        Vue.set(state,input,header[input])
        if (localStorage.rememberMe == "true") {
          localStorage.setItem(input, header[input])
        }else{
          sessionStorage.setItem(input, header[input])
        }
      }
      
    },

    clearAuthenticated(state){
      if(localStorage.rememberMe == "true"){
        localStorage.removeItem('access')
        localStorage.removeItem('refresh')
      }else{
        sessionStorage.removeItem('access')
        sessionStorage.removeItem('refresh')
        localStorage.removeItem('username')
      }
      state.authenticated = false;
      state.access = "";
      state.refresh = "";
    },
  },

  actions:{
    async authenticate(context, credentials){
      try{
        let response = await Axios.post(authUrl,credentials)
        context.commit("setAuthenticated",response.data);
        return 1
      }catch{
        return -1
      }
    },
    passwordRenewalRequest(context,email){
      const content = {email:email}
      Axios.put(renewalRequestUrl,content)
      .then(function(response){
        console.log(response)
      })
    },
    passwordRenewal(context,credentials){
       Axios.put(renewalRequest,credentials)
       .then(function(response){
         console.log(response)
        router.push("/login?result=successful")
      })
      .catch(function(error){
        console.log(error)
        router.push("/login?result=failed")
      })
    },
    async RegisterRequest(context,credentials){
      try{
        await Axios.post(registerUrl,credentials)
        router.push("/login?result=registered")
      }catch(error){
        if (error.response.data.email){
          return -2
        }else{
          return -1
        }
         
      }
   },
   async ActivateUser(context,credentials){
    try{
      await Axios.get(registerUrl,{params:credentials})
      router.push("/login?result=activated")
    }catch{
      router.push("/login?result=activation_failed")      
    }
 },
  }
}