<template>
  <b-modal  class="fullModal4Mobile" size="lg" id="modal-1" hide-footer :title=GraphModalHeader  @show="resetModalGraph" @hidden="resetModalGraph">
    <div class="d-flex" > 
      <div class="Graphscreens animate m-auto">
        <div class="mt-3 screen " v-bind:class="{'active': currentGraph === 0 }">
          <WG  :title=GraphModalHeader :pColor="['#1BD170', '#F63E3E', '#797979']"  :chartD=WorkBars4G :vAxisTitle=vAxisTitleSureler />
        </div>
        <div class="mt-3 screen" v-bind:class="{'active': currentGraph === 1 }">
          <WG  :title=GraphModalHeader :pColor=productColors :chartD=ProdBars4G :vAxisTitle=vAxisTitleAdetler />
        </div>
      </div>
    <button class="prevScreen" v-on:click="PrevGraph" >
      <i class="fa fa-angle-left fa-3" aria-hidden="true"></i>
    </button>
    <button class="nextScreen" v-on:click="NextGraph" >
      <i class="fa fa-angle-right fa-3" aria-hidden="true"></i>
    </button>
  </div>
</b-modal>
</template>

<script>
import WG from '@/components/UserDevice/GraphModal1/WorkGraph.vue'
export default {
  name:'GraphModal1',
  props: ['productColors','WorkBars4G','ProdBars4G'],
  components: {
    WG
  },
  data() {
    return{
      GraphModalHeader: "Detaylı Çalışma Grafiği",
      currentGraph: 0,
      vAxisTitleAdetler:"Üretim Adetleri",
      vAxisTitleSureler: "Çalışma Süresi (0 - 30 dk)"
    }
  },
  methods:{
    resetModalGraph(){
      this.currentGraph = 0
    }, 
    NextGraph(){
      if(this.currentGraph<1){
        this.currentGraph += 1
        this.GraphModalHeader = "Detaylı Parça Üretim Grafiği" 
      }
    },
    PrevGraph(){
      if(this.currentGraph>0){
        this.currentGraph -= 1
        this.GraphModalHeader = "Detaylı Çalışma Grafiği" 
      }
    },
  }
}
</script>

<style>
.Graphscreens{
  flex:1;
  align-self: stretch;
  order:2;
  width: 18em;
  position:relative;
  overflow: hidden;
}
@media (max-width: 960px) {
  div.modal-lg {
    max-width: 100%;
    margin: 0;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    position: fixed;
    z-index: 100000;
}
}
</style>