<template>

    <div id="terms" v-html="markdownToHtml"></div>

</template>


<script>
import {marked} from 'marked';
export default {
  name:'Terms',
   data(){
   return {
     markdown: 
'### 1. Tanımlar\n'+
'**Ardeo:** Ardeo Ltd Şti.\n'+
'\n'+
'**Müşteri:** Uygulama üzerinden cihazları hakkında bilgi alan kişi.\n'+
'\n'+
'**Taraf(lar):** Müşteri ve/veya Ardeo.\n'+
'\n'+
'**Koşullar:** Bu kullanım koşullarının tamamı.\n'+
'\n'+
'**Uygulama:** Ardeo’ya ait mobil ve diğer internet bağlantısı olan cihazlarda kullanılabilen Ardeo’nun sunduğu Ürünlerin/hizmetlerin sipariş edilebildiği tüm fikri ve sınai hakları Ardeo’e ait mobil uygulama veya web bazlı yazılım.\n'+
'\n'+
'**Sözleşme:** Taraflar arasındaki Ön bilgilendirme Formu ve bu formda yer alan bilgiler uyarınca kurulan mesafeli satış sözleşmesi.\n'+
'\n'+
'### 2. Başvuru ve Üyelik\n'+
'**2.1.** Kullanıcılar, Uygulama’yı indirip yada web sayfasına erişip, kayıt için gerekli olan bölümleri doldurup, email adresini onayladıktan sonra, şifresini girerek, işbu Koşullar dâhilinde Uygulama’yı kullanmaya başlayabilir.\n'+
'\n'+
'**2.2.** Müşteri, başvuru esnasında verdiği tüm bilgilerin daima ve her bakımdan eksiksiz, gerçeğe uygun ve güncel olduğunu kabul eder. Müşteri, bilgilerini her zaman Uygulama aracılığıyla güncelleyebilir.\n'+
'\n'+
'### 3. Uygulama\'nın Kullanımı\n'+
'**3.1.** Ardeo; Üretim verilerinizin uzaktan takibini sağlayabileceğiniz, anlık olarak makinenizin çalışma durumunu, verilmliliğini ve benzer kayıtları görebileceğiniz bir üründür.\n'+
' \n'+
'**3.2.** Müşteri’ye Uygulama üzerinden gösterilen  anlık olarak makinenizin çalışma durumunu, verilmliliğini ve benzer kayıtları o an alınan verilerle hesaplanan bilgilerdir. Ürün’ün sağladığı bilgilerden doğan durumlardan Ardeo sorumlu tutulamaz.\n'+
'\n'+
'\n'+
'### 4. Kişisel Verilerin Korunması\n'+
'**4.1.** Ardeo, kişisel verilerinizin korunmasına yüksek önem vermektedir. Kişisel verilerinize ilişkin düzenlemelere erişmek için aşağıdaki bağlantılara tıklayabilirsiniz:\n'+
'\n'+
'Güvenliğiniz bizim için önemli. Bu sebeple bizimle paylaşacağınız kişisel verileriz hassasiyetle korunmaktadır. Biz, Ardeo olarak, bu gizlilik ve kişisel verilerin korunması politikası ile, hangi kişisel verilerinizin hangi amaçla işleneceği, işlenen verilerin kimlerle ve neden paylaşılabileceği, veri işleme yöntemimiz ve hukuki sebeplerimiz ile; işlenen verilerinize ilişkin haklarınızın neler olduğu hususunda sizleri aydınlatmayı amaçlıyoruz. Bizimle paylaştığınız kişisel verileriniz sadece analiz yapmak suretiyle; sunduğumuz hizmetlerin gerekliliklerini en iyi şekilde yerine getirebilmek, bu hizmetlere sizin tarafınızdan ulaşılabilmesini ve maksiumum düzeyde faydalanılabilmesini sağlamak, hizmetlerimizi, ihtiyaçlarınız doğrultusunda geliştirebilmek ve sizleri daha geniş kapsamlı hizmet sağlayıcıları ile yasal çerçeveler içerisinde buluşturabilmek ve kanundan doğan zorunlulukların (kişisel verilerin talep halinde adli ve idari makamlarla paylaşılması) yerine getirilebilmesi amacıyla, sözleşme ve hizmet süresince, amacına uygun ve ölçülü bir şekilde işlenecek ve güncellenecektir. Bizimle paylaştığınız kişisel verileriniz; hiçbir kurum yada kuruluşlarla yada herhangi bir 3. Şahıs ile, kesinlikle paylaşılmamaktadır. Verileriniz gizli tutulmaktadır.\n'+
'\n'+
'**4.2.** Müşteri, Uygulama’da oluşturulmuş her bir kullanıcı için yalnızca bir adet email tanımayabileceğini, tanımlanan emailin değiştirilemeyeceğini, yeni bir emailin ancak Uygulama üzerinde yeni bir kullanıcı oluşturulması halinde tanımlatılabileceğini, kayıtlı email ile yapacağı her türlü işlemden bizzat kendisinin sorumlu olduğunu; kabul, beyan ve taahhüt eder.\n'+
'\n'+
'**4.3.** Müşteri, Kişisel Verilerinizin Korunmasına İlişkin Aydınlatma Metni doğrultusunda üyeliğinin silinmesini talep edebilir. Ardeo üyeliği silindikten sonra, Müşteri, silinen üyeliği tekrar aktif hale getiremeyecek ve üyelik bilgilerine Uygulama üzerinden erişemeyecektir. Kısa süre önce silinen üyeliklerle bağlantılı email üzerinden yeni üyelik oluşturulmasının, Uygulama’nın güvenliği için dahili sistemlerimiz tarafından belirlenen süre boyunca otomatik olarak askıya alınacağını lütfen unutmayın.\n'+
'\n'+
'### 5. Sona Erme\n'+
'**5.1.** Müşteri, Uygulama’yı dilediği zaman yüklediği cihazdan silerek kullanımına son verebilir.\n'+
'\n'+
'**5.2.** Müşteri’nin işbu belirtilen Koşullar’a, ilgili mevzuata veya iyi niyet/ahlak kurallarına aykırı davrandığının tespiti halinde, Ardeo Müşteri’nin üyeliğini askıya alma veya Uygulama’dan tamamen çıkarma hakkını saklı tutar.\n'+
'\n'+
'\n'+
'### 6. Fikri Mülkiyet Hakları\n'+
'**6.1.** Ardeo\'nun; Ardeo hizmetleri, fikri mülkiyet hakları, Ardeo ticari markaları, Ardeo ticari görünümü veya Uygulama’nın tasarım, metin, imge, html kodu ve diğer kodlar da dahil fakat bunlarla sınırlı olmamak kaydıyla, tüm unsurları, her tür ayni ve şahsi hakları, ticari bilgi ve know-how\'a yönelik tüm hakları münhasıran Ardeo’ya aittir.\n'+
'\n'+
'**6.2.** Müşteri, Ardeo hizmetlerini ve Ardeo\'nun yukarıda sayılan çalışmalarını ticari amaçla kullanamaz, çoğaltamaz, dağıtamaz veya bunlardan türetilmiş çalışmalar yapamaz veya hazırlayamaz.\n'+
'\n'+
'**6.3.** Müşteri, Uygulama dahilinde bulunan, Ardeo ve/veya başka bir üçüncü kişilere ait resimleri, metinleri, görsel ve işitsel imgeleri, video kliplerini, dosyaları, veri tabanlarını, katalogları ve listeleri çoğaltmayacağını, kopyalamayacağını, dağıtmayacağını, işlemeyeceğini kabul eder.\n'+
'\n'+
'### 7. Değişiklikler\n'+
'Ardeo, tamamen kendi takdirine bağlı ve tek taraflı olarak, işbu Koşullar’ı, uygun göreceği herhangi bir zamanda, Uygulama üzerinden ilan ederek değiştirebilir.\n'+
'\n'+
'### 8. Mücbir Sebepler\n'+
'Ulaşım kesintisi, altyapı ve internet arızaları, yangın, deprem, sel baskını, diğer doğal afetler ile salgın hastalık gibi olağanüstü olayları, kargaşa, yaygın şiddet hareketleri, grev veya resmi makamların düzenlemeleri dahil ancak bunlarla sınırlı olmaksızın yükümlülüklerini yerine getirmesini objektif olarak engelleyebilecek veya geciktirebilecek nitelikteki durumlarda Ardeo, işbu Koşullar ile belirlenen edimlerinden herhangi birini geç veya eksik ifa etme veya ifa etmeme nedeniyle sorumlu tutulamaz.\n'+
'\n'+
'### 9. Uygulanacak Hukuk Ve Yetki\n'+
'**9.1.** İşbu Koşullar Türkiye Cumhuriyeti yasalarına tabi olacak ve onlara göre yorumlanacaktır.\n'+
'\n'+
'**9.2.** Taraflar, Koşullar’dan doğan ihtilaflarda mevzuat çerçevesinde belirlenen parasal sınırlar dahilinde Müşteri ve ArdeHub’in kayıtlı adresinin bulunduğu yerdeki tüketici hakem heyetlerinin, parasal sınırları aşan uyuşmazlıklarda Müşteri’nin ve Ardeo’in bulunduğu yerdeki tüketici mahkemelerinin yetkili olacağını kabul etmiştir.\n'+
'\n'+
'**9.3.** Uyuşmazlığın tüketici uyuşmazlığı olmaması halinde, İstanbul Merkez (Çağlayan) Mahkemeleri ve İcra Daireleri yetkili olacaktır.\n'+
'\n'+
'### 10. Delil Sözleşmesi\n'+
'Taraflar, Koşullar’dan doğabilecek her türlü ihtilafta ArdeoHub’in resmi defter ve ticari kayıtlarıyla, veritabanında ve sunucularında tuttuğu elektronik bilgilerin, bilgisayar ve ses kayıtlarının, delil teşkil edeceğini, bu maddenin Hukuk Muhakemeleri Kanunu’nun 193. maddesi anlamında delil sözleşmesi niteliğinde olduğunu kabul eder.\n'+
'\n'+
'### 11. Yürürlük\n'+
'Müşteri Uygulama’yı cihazına yüklemesi veya web sayfasına erişmesi'
   };
 },
 computed: {
   markdownToHtml(){
     return marked(this.markdown);
   }

 }

}

</script>

<style>
#terms {

  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

}
#terms h3{
  text-align: left;
  font-size:0.8em;
  margin-top: 0.6em;
  margin-bottom: 0.3em;
}
#terms p{
  text-align: left;
  padding:0 0.3em 0 0.3em ;
  font-size:0.6em;
  margin-bottom: 0.3em;
}
</style>