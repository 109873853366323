<template>
  <div v-if="show" class="text-danger">
    <div v-for="m in messages" v-bind:key="m">{{ m }}</div>
  </div>
</template>
<script>
  export default {
    props: ["validation"],
    computed: { 
      show() {
        return this.validation.$dirty && this.validation.$invalid },        
      messages() {
        let messages = []; 
          if (this.validation.$dirty) {
            if (this.hasValidationError("required")) {
              messages.push("Lütfen bir değer giriniz")
            } else if (this.hasValidationError("email")) {
              messages.push("Lütfen geçerli bir mail adres giriniz.")
            } else if (this.hasValidationError("minLength")) {
              messages.push("En az "+this.validation.$params.minLength.min+" karakter olmalı.")
            } else if (this.hasValidationError("sameAsPassword")) {
              messages.push("Şifreler aynı olmalıdır.")
            } else if (this.hasValidationError("notNumeric")){
              messages.push("Şifre sadece sayılardan oluşamaz.")
            } else if (this.hasValidationError("sameAsEmail")) {
              messages.push("Emailler aynı olmalıdır.")
            }else if (this.hasValidationError("notAccepted")) {
              messages.push("Sözleşmeyi onaylayınız.")
            }
          }
          return messages;
      }
    },
    methods: {
      hasValidationError(type) {
        return Object.prototype.hasOwnProperty.call(this.validation.$params,type) && !this.validation[type]
      }    
    }
  }
</script>