<template>
  <div class="d-flex flex-column  h-100">
    <div style="position: absolute;right: 0.5em;">
      <router-link id="returnBack" to="login" tag="a"  > <i class="fa fa-arrow-circle-o-left fa-3x" aria-hidden="true"></i></router-link>
    </div>
    <div class="d-flex align-items-md-center justify-content-center h-100" >
      <div>
        <form>
          <validation-error v-bind:validation="$v.password" />
          <div class="input-group mb-4">
            <div  class="input-group-prepend igp-custom"><i class="input-group-text igt-custom  fa fa-key-modern"></i> </div>
            <input v-model="$v.password.$model"   class="form-control fci-custom" type="password" id="password" placeholder="Yeni şifrenizi giriniz." />
          </div>
          <validation-error v-bind:validation="$v.repassword" />
          <div class="input-group igp-custom mb-4">
            <div  class="input-group-prepend igp-custom"><i class="input-group-text igt-custom fa fa-key-modern"></i> </div>
            <input v-model="$v.repassword.$model"  class="form-control fci-custom" type="password" id="repassword" placeholder="Lütfen şifrenizi tekrar giriniz" />
          </div>
          <div v-if="showFailureMessage" class="bg-danger text-white text-center p-2 my-2">
            Şifreniz değiştirilemedi. Lütfen mailinizi kontrol ediniz.
          </div>
          <div class="d-flex justify-content-center">
            <button  v-on:click="handlePasswordReset" type="button" class="btn btn-custom px-4 mx-3 "> Şifreyi Değiştir</button>
          </div>
        </form>
      </div>
    </div>

  </div>


</template>

<script>
  import { required,minLength,sameAs,numeric, not  } from "vuelidate/lib/validators";
  import { mapActions } from "vuex";
  import ValidationError  from "./ValidationError";
  
  export default {
    components: { ValidationError },
    data: function() { return {
      password: "",
      repassword: "",
      showFailureMessage: false,
      }
    }, 
    computed: {
      token: function(){
        return this.$route.query.token
      }
    },
    validations: {
      password: { required, minLength: minLength(8), notNumeric: not(numeric) },
      repassword: { required, sameAsPassword: sameAs('password') },
    },
    methods: {
      ...mapActions(["passwordRenewal"]),
      handlePasswordReset(){
        this.onChange();
        this.$v.$touch();
        if (!this.$v.$invalid && this.token.length > 8) {
          this.passwordRenewal({ password: this.password, token: this.token});
        }else if(this.token.length <8){
          this.showFailureMessage = true;
        }
      },
      onChange(){
          this.showFailureMessage = false;
          this.showThankYou = false;
      }   
    }
  }
  
</script>

<style>
#returnBack {
color: #797979;
}
button.btn-custom {
  background: linear-gradient(
    to right,
    rgba(242, 102, 0, 1),
    rgba(242, 223, 0, 1)
  );
  font-family: acumin-pro, sans-serif;
  color: white;
  font-weight: 700;
  font-style: normal;
  font-size: 1.2rem;
  min-width: 10rem;
  background-size: calc(100% + 4px);
}

h3.form-title {
  font-family: acumin-pro, sans-serif;
  color: #797979;
  font-weight: 700;
  font-style: normal;
  font-size: 2rem;
}
input.fci-custom {
  background-color: #fcfcfc;
  border-left: 0 solid transparent;
}
i.igt-custom {
  color: #f26600;
  background-color: #fcfcfc;
}

div.igp-custom {
  border-right: 0;
}
div.fgl-custom {
  font-family: acumin-pro, sans-serif;
  color: #797979;
  font-weight: 700;
  font-style: normal;
  font-size: 1rem;
}

@media (max-width: 568px) {
h3.form-title {
  font-weight: 700;
  font-size: 1.5rem;
}
h3{
  padding-bottom: 1em;
  padding-top: 0.5em;
}
}
@media (min-width: 568px) {
  h3.form-title {
  font-weight: 700;
  font-size: 2rem;
}
}
</style>