<template>
  <div class="card text-center main-card-custom flex-grow-1 d-flex flex-sm-column flex-column-reverse">
    <div class="card-header main-card-header-custom d-flex">
    <ul id="navtab-ardeo" class="nav nav-tabs card-header-tabs">
      <li v-for="b in currentBraches" v-bind:key="b.id" class="nav-item">
        <router-link :to="{ name: 'Branch', params: { branchname: b.name , companyname: currentCompany.name } }" class="nav-link active" v-if="currentBranch.name == b.name" >{{b.name}}</router-link>
        <router-link :to="{ name: 'Branch', params: { branchname: b.name ,  companyname: currentCompany.name} }" class="nav-link passive" v-else>{{b.name}}</router-link>
      </li>
    </ul>
    <div class="popover-custom">
      <i class="fa fa-info-circle fa-2x text-white mr-3" aria-hidden="true" id="robo-filter" ></i>
      <b-popover target="robo-filter" triggers="click" placement="bottom">
        <template #title>Cihaz durumları</template>
        <i  class='fa fa-play-circle-o text-success' style='color:#1bd170;'></i>: Çalışıyor<br/> <i class='fa fa-stop-circle-o text-warning'></i>: Duruyor<br/> <i class='fa fa-exclamation-triangle text-danger'></i>: Acile basılı<br/> <i class='fa fa-folder-open fa-rotate-90 text-danger' ></i>: Kapı açık<br/> 
        <i class='fa fa-minus-circle text-primary text-danger'></i>: Bağlantı kurulamıyor<br/>
        <i class='fa fa-minus-circle '></i>: Bilinmeyen durum
      </b-popover>
    </div>
    </div>
    <div class="card-body main-card-body-custom">
        <div v-for="department in currentDepartments" v-bind:key="department.id" class="card d-flex flex-row mb-4 align-items-start ">
          <div class="card-title mt-0 ml-0 py-1 division-card-title-custom">{{department.name}}</div>
          <div class= "d-flex flex-row flex-wrap flex-grow-1" >
            <div v-for="device in department.user_devices" v-bind:key="device.id" class="card product-card-custom " v-b-popover.hover.right="new Date(device.date_updated).toLocaleString('en-GB', { timeZone: 'Europe/Istanbul' })" title="Son Durum Güncelleme" >
              <div class="card-header product-card-header-custom "> <router-link v-if="device.status !='disconnected'" :to="{ name: 'userDeviceMenu', params: { branchname: currentBranch.name, departmentname: department.name, user_device:device.name } }" @click.native="stop_sse">{{device.name}}</router-link >
              <div v-else>
                  {{device.name}}
              </div>
              </div>
              <div class="product" :status="device.status" >
                <img class="card-img-top product-image-custom m-auto py-2" src="../assets/img/robot-arm.svg" :alt="device.name">
              </div>
            </div>
            
          </div>
        </div>
    </div>

    <b-modal id="modal-1" hide-footer   style="overflow-y: auto" header-class="modalH">
    <template #modal-header> <div class="mx-auto">Hata</div><i class="fa fa-times p-2" aria-hidden="true" @click="closeModal()"></i></template>
        <p class="modalP">Cihazla iletişim sağlanamadı. Lütfen tekrar deneyin.</p>
    </b-modal>

  </div>
</template>

<script>
import { mapActions, mapGetters,mapMutations, mapState } from 'vuex'

export default {
  name:'All',
  data() {
    return {
      client: this.$sse.SSEClient ,
    };
  },
  created(){
    this.getBusiness()
    .then(result => {
      if(result == 1){
        if(this.currentCompany)
        if(this.currentBranch)
        if(this.currentCompany === -1 || this.currentBranch === -1){

            this.$router.push('/404')
        }
      }
    })
  },
  mounted(){
    console.log(this.companies)
    if(this.$route.query.result){
      if(this.$route.query.result == "fail2get"){
        this.$bvModal.show('modal-1')
      }
    }

  },
  beforeDestroy(){
    this.stop_sse()
  },
  watch:{
    $route(){
      this.getBusiness() 
    },
    currentBranch: function(){
      if (this.client !== undefined){
        this.client.disconnect()
      }

      //this.client = this.$sse.create(process.env.VUE_APP_API_SERVER+"/channels/statuses-"+this.currentBranch.id+"/events/")
      this.client = this.$sse.create({
        format: "json",
        polyfill: true, 
        url: process.env.VUE_APP_API_SERVER+"/channels/statuses-"+this.currentBranch.id+"/events/", 
        withCredentials: true, 
        polyfillOptions:{headers: {"Authorization": "Bearer "+this.jwt,}} ,
        forcePolyfill:true})

      this.client.connect()
      this.client.on('error', (e) => {
        console.error('lost connection or failed to parse!', e);
      });
      this.client.on('message', (m) => {
        console.log(m)
        this.$store.commit('updateStatus', m)
      });

    }
  },
  computed:{
    ...mapState({jwt: state => state.auth.access}),
    ...mapGetters(['currentCompany','currentBraches','currentBranch',
      'currentDepartments'
     ])
  },
  methods:{
    ...mapActions([
      'getBusiness'
    ]),
    ...mapMutations([
      'updateStatus'
    ]),
    stop_sse(){
       if (this.client !== undefined){
        this.client.disconnect()
       }
    },
    closeModal(){
      this.$bvModal.hide('modal-1')
    }
  }

}
</script>

<style>
  a.nav-link.active {
    font-family: acumin-pro, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 1rem;
    border: none !important; 
  }
  a.nav-link {
  font-family: acumin-pro, sans-serif;
  color: white;
  font-weight: 700;
  font-style: normal;
  font-size: 1rem;
}
  a.nav-link.passive {
  font-family: acumin-pro, sans-serif;
  color: white;
  font-weight: 700;
  font-style: normal;
  font-size: 0.93rem;
  border:  1px solid rgba(240, 240, 240,0.5);
}
div.product[status] {
  position: relative;
  margin-top: 1rem;
}

.product[status="disconnected"]::after {
  position: absolute;
  font-family: "ForkAwesome";
  top: -1rem;
  right: 1.3rem;
  content: "\f056 ";
  color: black;
  font-size: 2rem;
}
.product[status="unknown"]::after {
  position: absolute;
  font-family: "ForkAwesome";
  top: -1rem;
  right: 1.3rem;
  content: "\f056 ";
  color: red;
  font-size: 2rem;
}

.product[status="warning"]::after {
  position: absolute;
  font-family: "ForkAwesome";
  top: -1rem;
  right: 1.3rem;
  content: "\f071";
  color: red;
  font-size: 2rem;
}

.product[status="calisiyor"]::before {
  position: absolute;
  font-family: "ForkAwesome";
  right: 1.8rem;
  content: "\f04b";
  color: #1bd170;
  font-size: 0.7rem;
}
.product[status="calisiyor"]::after {
  position: absolute;
  font-family: "ForkAwesome";
  top: -1rem;
  right: 1.3rem;
  content: "\f021";
  color: #1bd170;
  font-size: 2rem;
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}
.product[status="durusta"]::after {
  position: absolute;
  font-family: "ForkAwesome";
  top: -1rem;
  right: 1.3rem;
  content: "\f28e";
  color: #f8d731;
  font-size: 2rem;
}

.product[status="door-open"]::after {
  position: absolute;
  font-family: "ForkAwesome";
  top: -1rem;
  right: 1.3rem;
  content: "\f07c";
  color: #ff5959;
  font-size: 2rem;
  transform: rotate(90deg);
}

div.product-card-custom {
  margin: 2rem 0.5rem 2rem 0.5rem;
  border-color: rgba(0, 140, 242, 1);
  flex: 0 0 150px;
}

div.popover-custom {
  position: absolute;
  font-family: acumin-pro, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1rem;
  right: 1rem;
}

div.division-card-title-custom {
  font-family: acumin-pro, sans-serif;
  color: #1a1a1a;
  font-weight: 700;
  font-style: normal;
  font-size: 1.2rem;
  border-radius: 0 5px 5px 0;
  background-color: #797979;
  background-image: linear-gradient(
    to left,
    rgba(0, 140, 242, 1),
    rgba(237, 237, 237, 1)
  );
  flex: 0 0 90px;
}

img.product-image-custom {
  width: 50%;
}
p.modalP{
  font-family: acumin-pro, sans-serif;
  color: #797979;
  font-weight: 700;
  font-style: normal;
  font-size: 1.2rem;
}
.modalH{
  font-family: acumin-pro, sans-serif;
  color: #191919;
  font-weight: 700;
  font-style: normal;
  font-size: 1.2rem;
  padding: 0.6rem 1rem !important;
}
div.product-card-header-custom {
  font-family: acumin-pro, sans-serif;
  color: black;
  font-weight: 400;
  font-style: normal;
  font-size: 1rem;

  line-height: 1;

  border-color: rgba(0, 140, 242, 1);
}


</style>