import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Initial from '../views/Initial.vue'
import PageNotFound from '../views/404.vue'
import tokenOps from '../scripts/slientRefresh'
Vue.use(VueRouter)

const routes = [
  {path: "/login", name:'Login', component: Initial, meta: {requiresAuth: false}},
  {path: "/register", name:'Register', component: Initial, meta: {requiresAuth: false}},
  {path: "/resetPassword", name:'ResetPassword',component: Initial, meta: {requiresAuth: false}},
  {path: "/companies", name:'Companies',component: Home, meta: {requiresAuth: true}},
  { path: '/', redirect: '/companies' },  
  { path: '/help', name: 'Help', component: Home,  meta: {requiresAuth: true}},  
  { path: '/profile', name: 'Profile', component: Home, meta: {requiresAuth: true} },
  { path: '/changePassword', name: 'ChangePassword', component: Home, meta: {requiresAuth: true} },
  { path: '/company/:companyname', name: 'Company', component: Home, meta: {requiresAuth: true}},
  { path: '/company/:companyname/branch/:branchname', name: 'Branch', component: Home, meta: {requiresAuth: true}},
  { path: '/company/:companyname/branch/:branchname/department/:departmentname/device/:user_device', name: 'userDeviceMenu', component: Home, meta: {requiresAuth: true}},
  {path: "*", component: PageNotFound, meta: {requiresAuth: false}},
  {path: "/404", component: PageNotFound, meta: {requiresAuth: false}}
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next)=>{
  if (!to.matched.length) {
    next('/notFound');
  } else {
    if ( to.meta.requiresAuth) {
      tokenOps.renewAccessToken()
      .then((result)=>{
        if (result==false) {
          next({ name: 'Login' })
        }else{
          next()
        }
      })
    } else {
      if (to.name == 'Login' ||  to.name == 'Register'){
        tokenOps.renewAccessToken()
        .then((result)=>{
          if(result == true){
            next({name: 'Home' })
          }else{
            next()
          }     
        })
      }else{
        next()}
    }}})
export default router
